// global computed baseUrl (this.baseUrl)
// global computed deviceToken (this.deviceToken)
// global computed session (this.session)
// global method post (this.post(url, data))
export default {
    computed: {
        baseUrl() {
            return process.env.VUE_APP_API_URL + ''
        },
    },
    methods: {
        post(url, data) {
            return this.axios.post(this.baseUrl + url, {
                data: data
            }).then((response) => {
                return response
            })
        }
    }
}
