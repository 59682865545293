/**
 * setup server with history mode
 * https://router.vuejs.org/guide/essentials/history-mode.html
 */

import Home from "../components/Home";
import EstablishmentChoice from "../components/EstablishmentChoice";
import Appointment from "../components/Appointment";
import Confirmation from "../components/Confirmation";
import Portal from "../components/Portal";
import Password from "../components/Password";
import Landing from "../components/Landing";
import Register from "../components/Register";
import Hzz from "../components/Hzz";

export const routes = [
    {path: '/:practiceHash/', name: 'home', component: Home},
    {path: '/:practiceHash/entry/:next?', name: 'home-next', component: Home},
    {path: '/:practiceHash/register', name: 'register', component: Register},
    {path: '/:practiceHash/hzz/:establishment?/:treatment?', name: 'hzz', component: Hzz},
    {path: '/:practiceHash/establishment/:treatment?', name: 'establishment', component: EstablishmentChoice},
    {path: '/:practiceHash/appointment', name: 'appointment', component: Appointment},
    {path: '/:practiceHash/confirmation', name: 'confirmation', component: Confirmation},
    {path: '/:practiceHash/portal', name: 'portal', component: Portal},
    {path: '/:practiceHash/password/:emailToken', component: Password},
    {path: '/:practiceHash/*', name: 'landing-error', component: Landing},
    {path: '/', name: 'landing', component: Landing}
];

