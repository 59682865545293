<template>
    <div
        id="portal"
        class="col-12 col-sm-9"
    >
        <div class="header-bar d-flex justify-content-between">
            <h4>Portaal</h4>
            <LogOffButton></LogOffButton>
        </div>
        <div v-if="multipleInstances" class="error container">
          <p>
            We zien dat u meerdere accounts op dit email adres hebt.
            Heeft u hiervoor vaker hetzelfde wachtwoord gebruikt? Dan kan het zijn dat u niet in het verwachte portaal komt.
            Verander dan de wachtwoorden zodat deze allemaal uniek zijn.<button class="btn btn-close btn-sm error" v-on:click="closeMessage()">X</button>
          </p>
        </div>
        <div
            class="container"
            v-if="patient"
        >
            <div class="row">
                <div class="col">
                    <h5>Uw gegevens</h5>
                    <p id="patient-data">
                        <b>{{ patient.firstName }} {{ patient.infix }} {{ patient.lastName }}</b>
                        <br> {{ patient.dateOfBirth | moment("DD-MM-YYYY") }}
                        <br> {{ patient.street }} {{ patient.addressNumber }} {{ patient.addressNumberAddition }}
                        <br> {{ patient.postalCode }} {{ patient.city }}
                        <br> {{ patient.email }}
                        <br>
                        <span v-if="patient.phoneNumber_1">{{ patient.phoneNumber_1 }}</span>
                        <span v-if="patient['mobile']">{{ patient.mobile }}</span>
                    </p>
                </div>
                <div
                    class="col"
                    v-if="practice"
                >

                </div>
            </div>
        </div>
        <b-container fluid="true">
            <b-row>
                <b-col v-if="intakeEnabled">
                    <div class="justify-content-center d-flex">
                        <button
                            id="new-intake"
                            class="btn btn-primary"
                            @click="this.setIntake"
                        >
                            Nieuw behandeltraject starten
                        </button>
                    </div>
                </b-col>
                <b-col>
                    <div class="justify-content-center d-flex">
                        <button
                            id="plan-appointment-btn"
                            class="btn btn-primary"
                            @click="this.newAppointment"
                        >
                            Vervolg behandeling plannen
                        </button>
                    </div>
                </b-col>
            </b-row>
        </b-container>
        <b-container v-if="futureAppointments ">
            <div>
                <br><br>
                <h4>Uw aankomende afspraken</h4>
            </div>
            <b-row class="table-responsive">
                <b-table-simple>
                    <tr>
                        <th>Behandelaar</th>
                        <th>Behandeling</th>
                        <th>Locatie</th>
                        <th>Datum</th>
                        <th>Tijdstip</th>
                        <th></th>
                    </tr>
                    <tr
                        v-for="appointment in futureAppointments"
                        v-bind:key="appointment.id"
                    >
                        <td>{{ appointment.employeeName || '' }}</td>
                        <td>{{ appointment.treatmentName }}</td>
                        <td>
                            {{ getEstablishmentById(appointment.establishment).name }} <br>
                            {{ getEstablishmentById(appointment.establishment).street }}
                            {{ getEstablishmentById(appointment.establishment).number }} <br>
                            {{ getEstablishmentById(appointment.establishment).postalCode }}
                            {{ getEstablishmentById(appointment.establishment).city }}
                        </td>
                        <td> {{ appointment.date | moment("dddd, DD-MM-YYYY") }}</td>
                        <td> {{ $moment(appointment.time, 'HH:mm').format('LT') }}</td>
                        <td>
                            <div v-if="mutationSettings.reschedule.enabled && appointment.mutatable && appointment.mutatable.reschedule">
                                <button
                                    class="btn btn-outline-dark btn-reschedule"
                                    v-on:click="setReschedule(appointment)"
                                    style="width: 125px"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-calendar-event"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z"/>
                                        <path
                                            d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"
                                        />
                                    </svg>
                                    Verzetten
                                </button>
                                <br>
                            </div>
                            <div v-if="mutationSettings.cancellation.enabled && appointment.mutatable && appointment.mutatable.cancellation">
                                <button
                                    class="btn btn-outline-secondary btn-cancel"
                                    v-on:click="setDelete(appointment)"
                                    style="width: 125px"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-x-lg"
                                        viewBox="0 0 16 16"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
                                        />
                                        <path
                                            fill-rule="evenodd"
                                            d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
                                        />
                                    </svg>
                                    Annuleren
                                </button>
                            </div>
                        </td>
                    </tr>
                </b-table-simple>
            </b-row>
        </b-container>
        <Modal v-if="showModal">
            <h3
                slot="header"
                class="modal-title"
            >
                {{ modal.header }}
            </h3>
            <button
                slot="header"
                type="button"
                class="close"
                v-on:click="cancel(modal.type)"
            >
                <span aria-hidden="true">&times;</span>
            </button>
            <div
                slot="body"
                style="padding: 20px"
            >
                <p>{{ modal.body }}</p>
            </div>
            <div slot="footer">
                <button
                    type="button"
                    class="btn btn-primary"
                    v-on:click="cancel(modal.type)"
                >Terug
                </button>
                <button
                    type="button"
                    v-bind:class="modal.button.style"
                    v-on:click="mutation(modal.type)"
                >
                    {{ modal.button.text }}
                </button>
            </div>
        </Modal>
    </div>
</template>

<script>
import axios from "axios";
import LogOffButton from "./Button/LogOffButton";
import calls from "../mixins/calls"
import appointmentCalls from "../mixins/appointmentCalls";
import Modal from "./Modal";

export default {
    name: 'Portal',
    mixins: [calls, appointmentCalls],
    components: {LogOffButton, Modal},
    created() {
        this.checkToken();
        this.tokenString = '&api-token=' + this.$store.state.token
        this.getPatient();
        this.getEstablishments()

      this.$store.commit('setSelectedEpisode', null)
        this.$store.commit('setSelectedTreatment', null)
        this.$store.commit('setTreatmentData', null)
        this.$store.commit('setRescheduleData', [])
        this.$store.commit('setReschedule', false)
    },
    data() {
        return {
            patient: null,
            futureAppointments: [],
            practice: null,
            establishments: [],
            tokenString: '',
            employees: [],
            showModal: false,
            idDeleteAppointment: null,
            modal: [],
        }
    },
    methods: {
        getPatient() {
            axios.get(process.env.VUE_APP_API_URL + 'patient/' + this.idPatient + '/?practice=' + this.practiceCode + '&api-token=' + this.$store.state.token, {
                params: {logToken: this.logToken}
            }).then((response) => {
                this.patient = response.data;
                if (this.$store.state.postalCodeCheck) {
                    this.$store.commit('setPatientPostalCode', this.patient.postalCode)
                }
            });
        },
        getAppointments() {
            this.idDeleteAppointment = null
            this.futureAppointments = []
            axios.get(process.env.VUE_APP_API_URL + 'appointment/?practice=' + this.practiceCode + '&api-token=' + this.$store.state.token + '&patient=' + this.idPatient, {
                params: {logToken: this.logToken}
            }).then((response) => {
                let results = response.data;
                let vm = this;
                let date = this.$moment({})
                let today = date.format("YYYY-MM-DD");
                let checkDateCancellation = date.clone().add(this.mutationSettings.cancellation.hours, 'hours').format('YYYY-MM-DD HH:mm:ss');
                let checkDateRescheduling = date.clone().add(this.mutationSettings.reschedule.hours, 'hours').format('YYYY-MM-DD HH:mm:ss');
                let mutationSettings = this.$store.getters.getMutationSettings
                if (results.length > 0) {
                    results.forEach(function (appointment) {
                        let cancellation = false
                        let reschedule = false
                        if (appointment.date >= today) {
                            if (mutationSettings.cancellation.enabled && (checkDateCancellation <= vm.$moment(appointment.date + ' ' + appointment.time).format("YYYY-MM-DD hh:mm:ss"))) {
                                cancellation = true
                            }
                            if (mutationSettings.reschedule.enabled && (checkDateRescheduling <= vm.$moment(appointment.date + ' ' + appointment.time).format("YYYY-MM-DD hh:mm:ss"))) {
                                reschedule = true
                            }
                            if (reschedule || cancellation) {
                                appointment.mutatable = {'cancellation': cancellation, 'reschedule': reschedule}
                            }
                            vm.futureAppointments.push(appointment)
                            vm.getEmployee(appointment.employee).then((employeeResponse) => {
                                vm.addEmployeeName(employeeResponse)
                            })
                            vm.getTreatmentById(appointment.treatment).then((treatmentResponse) => {
                                vm.addTreatmentName(treatmentResponse)
                            })
                        }
                    })
                    vm.futureAppointments.sort((a, b) => new Date(a.date) - new Date(b.date))
                }
            })
        },
        addEmployeeName(employee) {
            for (let i = 0; i < this.futureAppointments.length; i++) {
                let appointment = this.futureAppointments[i]
                if (appointment.employee === employee.id) {
                    appointment.employeeName = employee.firstName
                    this.$set(this.futureAppointments, i, appointment)
                }
            }
        },
        addTreatmentName(treatment) {
            for (let i = 0; i < this.futureAppointments.length; i++) {
                let appointment = this.futureAppointments[i]
                if (appointment.treatment === treatment.id) {
                    appointment.treatmentName = treatment.name
                    this.$set(this.futureAppointments, i, appointment)
                }
            }
        },
        getEmployee(idEmployee) {
            if (this.employees[idEmployee]) {
                return this.employees[idEmployee]
            } else {
                return axios.get(process.env.VUE_APP_API_URL + 'employee/' + idEmployee + '/?practice=' + this.practiceCode + '&api-token=' + this.$store.state.token, {
                    params: {logToken: this.logToken}
                }).then((response) => {
                    this.employees[idEmployee] = response.data
                    return response.data
                })
            }
        },
        getPractice(idPractice) {
            axios.get(process.env.VUE_APP_API_URL + 'practice/' + idPractice + '/?practice=' + this.practiceCode + '&api-token=' + this.$store.state.token, {
                params: {logToken: this.logToken}
            }).then((response) => {
                this.practice = response.data;
            })
        },
        setIntake() {
            this.$store.commit('setIntake', true)
            this.$store.commit('setSelectedEpisode', null)
            this.$router.push({
                name: 'establishment',
                params: {
                    practiceHash: this.$store.state.practiceHash
                }
            })
        },
        newAppointment() {
            this.$store.commit('setIntake', false)
            this.$router.push({
                name: 'establishment',
                params: {practiceHash: this.$store.state.practiceHash}
            })
        },
        getEstablishments() {
            axios.get(process.env.VUE_APP_API_URL + 'establishment/?practice=' + this.practiceCode + this.tokenString, {
                params: {logToken: this.logToken}
            }).then((response) => {
                this.establishments = response.data;
                let activeEstablishments = [];
                if (this.establishments.length === 1) {
                    this.selectedEstablishment = this.establishments[0]
                } else {
                    this.establishments.forEach(function (establishment) {
                        if (establishment.active === '1') {
                            activeEstablishments.push(establishment);
                        }
                    })
                    this.establishments = activeEstablishments
                    this.$store.commit('setEstablishments', this.establishments)
                }
            })
        },
        getEstablishmentById(idLocation) {
            let location = this.locations.filter(function (elem) {
                if (elem.id === idLocation) return elem;
            })
            return this.establishments.filter(function (establishment) {
                return establishment.id === location[0].idEstablishment
            })[0]
        },
        checkToken() {
            if (!this.$store.state.token || this.$store.state.tempToken) {
                this.$router.push({
                    name: 'home'
                })
            }
        },
        setReschedule(appointment) {
            let data = []
            data.idEpisode = appointment.episode
            data.idEstablishment = (this.getEstablishmentById(appointment.establishment)).id
            data.appointment = appointment
            this.$store.commit("setRescheduleData", data)
            this.$store.commit('setReschedule', true)
            //set modal data
            this.modal.header = this.mutationSettings.reschedule.header
            this.modal.body = this.mutationSettings.reschedule.body
            this.modal.type = 'reschedule'
            this.modal.button = {text: 'Doorgaan', style: 'btn btn-success'}
            this.modal.action = this.reschedule
            this.showModal = true
        },
        reschedule() {
            this.newAppointment()
        },
        setDelete(appointment) {
            this.idDeleteAppointment = appointment.id
            this.modal.header = this.mutationSettings.cancellation.header
            this.modal.body = this.mutationSettings.cancellation.body
            this.modal.type = 'delete'
            this.modal.button = {text: 'Verwijder', style: 'btn btn-warning'}
            this.showModal = true
        },
        cancel(type) {
            this.modal = []
            if (type === 'delete') {
                this.idDeleteAppointment = null
            } else if (type === 'reschedule') {
                this.$store.commit('setRescheduleData', [])
            }
            this.showModal = false
        },
        mutation(type) {
            if (type === 'delete') {
                if (this.cancelAppointment(this.idDeleteAppointment)) {
                    let i = this.futureAppointments.map(item => item.id).indexOf(this.idDeleteAppointment)
                    this.futureAppointments.splice(i, 1)
                }
            } else if (type === 'reschedule') {
                this.reschedule()
            }
            this.showModal = false
        },
      closeMessage() {
          this.$store.commit('setMultipleInstances', false)
      }
    },
    computed: {
        token() {
            return this.$store.getters.getToken
        }
        ,
        idPatient() {
            return this.$store.getters.getIdPatient
        }
        ,
        practiceCode() {
            return this.$store.getters.getPracticeCode
        }
        ,
        locations() {
            return this.$store.getters.getLocations
        },
        intakeEnabled() {
            return this.$store.getters.getIntakeEnabled
        },
        mutationSettings() {
            return this.$store.getters.getMutationSettings
        },
        logToken() {
            return this.$store.getters.getLogToken
        },
      multipleInstances() {
            return this.$store.getters.getMultipleInstances
        }
    },
    watch: {
        patient: function () {
            this.getAppointments();
            this.getPractice(this.patient.practice)
        },
    }
}
</script>

<style scoped>
</style>
