<template>
    <welcome :next="next"></welcome>
</template>

<script>


import Welcome from "./Welcome";

export default {
    components: {Welcome},
    data() {
        return {
            practiceId: this.$store.state.practiceCode,
            practiceCode: null,
            next: 'fullPage',
        }
    },
    name: "Home",
    methods: {
        loggedIn() {
            if (this.token && !this.$store.state.tempIdPatient) {
                this.$router.push({
                    name: 'portal',
                    params: {practiceHash: this.$store.state.practiceHash}
                })
            } else {
                this.$store.commit('setToken', null)
            }
        }
    },
    created() {
        this.practiceCode = this.$store.state.practiceCode
        this.$store.commit('setPracticeHash', this.$route.params.practiceHash);
        this.$store.commit('setIdPatient', null);
        this.$store.commit('setHzzEntry', false);
        this.$store.commit('setHzzData', []);
        this.$store.commit('setTempToken', false)
        this.$store.commit('setTempIdPatient', false)
        if (this.$route.params.next) {
            this.next = this.$route.params.next
        }
        this.loggedIn();
    },
    computed: {
        token() {
            return this.$store.state.token
        },
        practiceHash() {
            return this.$store.state.practiceHash
        },
        logo() {
            return this.$store.state.logo
        }
    },
}
</script>

<style scoped>

</style>