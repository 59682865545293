<template>
    <span class="go-back">
        <div class="d-flex justify-content-center">
          <button
              @click="goBack"
              class="btn btn-back"
          >Ga terug</button>
        </div>
    </span>
</template>

<script>
export default {
    name: "GoBack",
    methods: {
        goBack() {
            if (
                this.lastRoute === 'register'
                &&
                this.$store.getters.getIdPatient
            ) {
                this.$store.commit('setLastRoute', null)
                this.$router.push({
                    name: 'date-selection',
                    params: {practiceHash: this.$store.state.practiceHash}
                })
            } else {
                return this.$router.go(-1)
            }
        }
    },
    computed: {
        lastRoute() {
            return this.$store.state.lastRoute
        }
    }
}
</script>