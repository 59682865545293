<template>
    <div class="col-12 col-sm-9">

        <div class="header-bar">
            <h4>Bevestiging</h4>
        </div>
        <div class="card-body">
            <div
                class="card-body justify-content-center d-flex"
                v-if="!confirmMessage"
            >
                <p id="confirmation-text">
                    Uw afspraak is gemaakt, u ontvangt een bevestiging via uw e-mail
                </p>
            </div>
            <div v-else>
                Uw afspraak is gemaakt. We konden helaas geen bevestiging per mail verzenden.
            </div>
            <b-row>
                <b-col v-if="token && !tempIdPatient && !accountLess">
                    <div class="d-flex justify-content-center">
                        <button
                            id="to-portal-btn"
                            class="btn btn-primary"
                            @click="toPortal"
                        >Terug naar portal
                        </button>
                    </div>
                </b-col>
                <b-col>
                    <div
                        class="d-flex justify-content-center"
                        v-if="token && !tempIdPatient && !accountLess"
                    >
                        <button
                            id="log-off-btn"
                            class="btn btn-outline-secondary btn-logoff"
                            @click="logOff"
                        >Log uit
                        </button>
                    </div>
                    <div
                        class="d-flex justify-content-center"
                        v-else
                    >
                        <button
                            class="btn btn-primary"
                            @click="logOff"
                        >
                            Afsluiten
                        </button>
                    </div>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import calls from "@/mixins/calls";

export default {
    name: 'Confirmation',
    mixins: [calls],
    methods: {
        toPortal() {
            this.$router.push({
                name: 'portal',
                params: {practiceHash: this.$route.params.practiceHash}
            })        },
    },
    computed: {
        token() {
            return this.$store.getters.getToken;
        },
        confirmMessage() {
            return this.$store.getters.getConfirmMessage
        },
        tempIdPatient() {
            return this.$store.getters.getTempIdPatient
        },
        accountLess() {
            return this.$store.getters.getAccountLess
        }
    }
}
</script>

<style scoped>

</style>