import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate'
import {router} from "@/main"
import VueGtm from '@gtm-support/vue2-gtm';

Vue.use(Vuex);


export const store = new Vuex.Store({
    plugins: [createPersistedState({
        storage: window.sessionStorage,
    })],
    state: {
        idPatient: null,
        tempIdPatient: false,
        patientPostalCode: null,
        patientEmail: null,
        loggedIn: false,
        intake: false,
        selectedEstablishment: null,
        selectedEpisode: null,
        selectedTreatment: null,
        employees: [],
        newPatient: false,
        diseases: [],
        token: null,
        tempToken: false,
        practiceHash: null,
        practiceCode: null,
        appointmentOptions: [],
        appointmentOptionDates: [],
        intakeOptions: [],
        postArray: [],
        selectedAppointment: null,
        lastRoute: null,
        postalCodeCheck: false,
        daysOpenClosed: [],
        establishments: [],
        locations: [],
        gtmId: null,
        confirmMessage: null,
        days: [
            {
                id: '0',
                day: 'zondag',
                enabled: false,
                timeStart: null,
                timeEnd: null
            },
            {
                id: 1,
                day: 'maandag',
                enabled: false,
                timeStart: null,
                timeEnd: null
            },
            {
                id: 2,
                day: 'dinsdag',
                enabled: false,
                timeStart: null,
                timeEnd: null
            },
            {
                id: 3,
                day: 'woensdag',
                enabled: false,
                timeStart: null,
                timeEnd: null
            },
            {
                id: 4,
                day: 'donderdag',
                enabled: false,
                timeStart: null,
                timeEnd: null
            },
            {
                id: 5,
                day: 'vrijdag',
                enabled: false,
                timeStart: null,
                timeEnd: null
            },
            {
                id: 6,
                day: 'zaterdag',
                enabled: false,
                timeStart: null,
                timeEnd: null
            }
        ],
        redirectUrl: false,
        headerInfo: [],
        logo: null,
        accountLess: false,
        showDescriptionPrice: false,
        showEstablishmentAddress: false,
        hzzEntry: false,
        hzzData: [],
        rescheduleData: [],
        intakeEnabled: false,
        intakeText: [],
        mutationSettings: [],
        reschedule: false,
        colors: [],
        appointmentOptionsReady: false,
        numberOfOptions: 0,
        logToken: false,
        pageHistory: [],
        weeksAhead: 3,
        minHoursAhead: 24,
        treatmentData: [],
        multipleInstances: false,
        showPatientRemark: false,
        patientRemarkEnabled: false,
        patientRemark: null
    },
    getters: {
        getSelectedEstablishment: state => {
            return state.selectedEstablishment
        },
        getSelectedEpisode: state => {
            return state.selectedEpisode
        },
        getIdPatient: state => {
            return state.idPatient
        },
        getTempIdPatient: state => {
            return state.tempIdPatient
        },
        getEmployees: state => {
            return state.employees
        },
        getEstablishments: state => {
            return state.establishments
        },
        getDiseases: state => {
            return state.diseases
        },
        getToken: state => {
            return state.token
        },
        getTempToken: state => {
            return state.tempToken
        },
        getPracticeHash: state => {
            return state.practiceHash
        },
        getIntake: state => {
            return state.intake
        },
        getPracticeCode: state => {
            return state.practiceCode;
        },
        getAppointmentOptions: state => {
            return state.appointmentOptions
        },
        getAppointmentOptionDates: state => {
            return state.appointmentOptionDates
        },
        getSelectedTreatment: state => {
            return state.selectedTreatment
        },
        getIntakeOptions: state => {
            return state.intakeOptions
        },
        getPostArray: state => {
            return state.postArray
        },
        getSelectedAppointment: state => {
            return state.selectedAppointment
        },
        getPatientEmail: state => {
            return state.patientEmail
        },
        getLastRoute: state => {
            return state.lastRoute
        },
        getPostalCodeCheck: state => {
            return state.postalCodeCheck
        },
        getPatientPostalCode: state => {
            return state.patientPostalCode
        },
        getDaysOpenClosed: state => {
            return state.daysOpenClosed
        },
        getStartDayId: state => {
            return state.startDayId
        },
        getRedirectUrl: state => {
            return state.redirectUrl
        },
        getLocations: state => {
            return state.locations
        },
        getGtmId: state => {
            return state.gtmId
        },
        getHeaderInfo: state => {
            return state.headerInfo
        },
        getLogo: state => {
            return state.logo
        },
        getConfirmMessage: state => {
            return state.confirmMessage
        },
        getAccountLess: state => {
            return state.accountLess
        },
        getShowDescriptionPrice: state => {
            return state.showDescriptionPrice
        },
        getShowEstablishmentAddress: state => {
            return state.showEstablishmentAddress
        },
        getShowHeader: state => {
            return state.headerInfo.showHeader
        },
        getHzzEntry: state => {
            return state.hzzEntry
        },
        getHzzData: state => {
            return state.hzzData
        },
        getRescheduleData: state => {
            return state.rescheduleData
        },
        getIntakeEnabled: state => {
            return state.intakeEnabled
        },
        getIntakeText: state => {
            return state.intakeText
        },
        getMutationSettings: state => {
            return state.mutationSettings
        },
        getReschedule: state => {
            return state.reschedule
        },
        getColors: state => {
            return state.colors
        },
        getWeeksAhead: state => {
            return state.weeksAhead
        },
        getMinHoursAhead: state => {
            return state.minHoursAhead
        },
        getAppointmentOptionsReady: state => {
            return state.appointmentOptionsReady
        },
        getLogToken: state => {
            return state.logToken
        },
        getPageHistory: state => {
            return state.pageHistory
        },
        getTreatmentData: state => {
            return state.treatmentData
        },
        getMultipleInstances: state => {
            return state.multipleInstances
        },
        getShowPatientRemark: state => {
            return state.showPatientRemark
        },
        getPatientRemarkEnabled: state => {
            return state.patientRemarkEnabled
        },
        getPatientRemark: state => {
            return state.patientRemark
        },
        getNumberOfOptions: state => {
            return state.numberOfOptions
        }
    },
    mutations: {
        setSelectedEstablishment: (state, payload) => {
            if (payload) {
                state.selectedEstablishment = payload
            } else {
                state.selectedEstablishment = null
            }
        },
        setSelectedEpisode: (state, payload) => {
            if (payload) {
                state.selectedEpisode = payload
            } else {
                state.selectedEpisode = null
            }
        },
        setIdPatient: (state, payload) => {
            if (payload) {
                state.idPatient = payload;
            } else {
                state.idPatient = null;
            }
        },
        setTempIdPatient: (state, payload) => {
            if (payload) {
                state.tempIdPatient = payload;
            } else {
                state.tempIdPatient = false;
            }
        },
        setEmployees: (state, payload) => {
            if (payload) {
                state.employees = payload;
            } else {
                state.employees = null;
            }
        },
        setEstablishments: (state, payload) => {
            if (payload) {
                state.establishments = payload;
            } else {
                state.establishments = null;
            }
        },
        setDiseases: (state, payload) => {
            if (payload) {
                state.diseases = payload;
            } else {
                state.diseases = null;
            }
        },
        setNewPatient: (state, payload) => {
            if (payload) {
                state.newPatient = payload;
            } else {
                state.newPatient = false;
            }
        },
        setLoggedIn: (state, payload) => {
            if (payload) {
                state.loggedIn = payload;
            } else {
                state.loggedIn = false;
            }
        },
        setToken: (state, payload) => {
            if (payload) {
                state.token = payload;
            } else {
                state.token = null;
            }
        },
        setTempToken: (state, payload) => {
            if (payload) {
                state.tempToken = payload;
            } else {
                state.tempToken = false;
            }
        },
        setPracticeHash: (state, payload) => {
            if (payload) {
                state.practiceHash = payload;
            } else {
                state.practiceHash = null;
            }
        },
        setIntake: (state, payload) => {
            if (payload) {
                state.intake = payload;
            } else {
                state.intake = false
            }
        },
        setAppointmentOptions: (state, payload) => {
            if (payload) {
                state.appointmentOptions = payload
            } else {
                state.appointmentOptions = []
            }
        },
        setAppointmentOptionDates: (state, payload) => {
            if (payload) {
                state.appointmentOptionDates = payload
            } else {
                state.appointmentOptionDates = []
            }
        },
        setPracticeCode: (state, payload) => {
            if (payload) {
                state.practiceCode = payload;
            } else {
                state.practiceCode = null;
            }
        },
        setSelectedTreatment: (state, payload) => {
            if (payload) {
                state.selectedTreatment = payload;
            } else {
                state.selectedTreatment = null;
            }
        },
        setIntakeOptions: (state, payload) => {
            if (payload) {
                state.intakeOptions = payload
            } else {
                state.intakeOptions = []
            }
        },
        setDays: (state, payload) => {
            if (payload) {
                state.days = payload
            } else {
                state.days = []
            }
        },
        setPostArray: (state, payload) => {
            if (payload) {
                state.postArray = payload
            } else {
                state.postArray = []
            }
        },
        setSelectedAppointment: (state, payload) => {
            if (payload) {
                state.selectedAppointment = payload
            } else {
                state.selectedAppointment = null
            }
        },
        setPatientEmail: (state, payload) => {
            if (payload) {
                state.patientEmail = payload
            } else {
                state.patientEmail = null
            }
        },
        setLastRoute: (state, payload) => {
            if (payload) {
                state.lastRoute = payload
            } else {
                state.lastRoute = null
            }
        },
        setPostalCodeCheck: (state, payload) => {
            if (payload) {
                state.postalCodeCheck = payload
            } else {
                state.postalCodeCheck = false
            }
        },
        setPatientPostalCode: (state, payload) => {
            if (payload) {
                state.patientPostalCode = payload
            } else {
                state.patientPostalCode = null
            }
        },
        setDaysOpenClosed: (state, payload) => {
            if (payload) {
                state.daysOpenClosed = payload
            } else {
                state.daysOpenClosed = false
            }
        },
        setStartDayId: (state, payload) => {
            if (payload) {
                state.startDayId = payload
            } else {
                state.startDayId = 1
            }
        },
        setLocations: (state, payload) => {
            if (payload) {
                state.locations = payload
            } else {
                state.locations = null
            }
        },
        setConfirmMessage: (state, payload) => {
            if (payload) {
                state.confirmMessage = payload
            } else {
                state.confirmMessage = null
            }
        },
        setGtmId: (state, payload) => {
            if (payload && payload !== false) {
                state.gtmId = payload.gtmId
                Vue.use(VueGtm, {
                    id: payload.gtmId, // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryPararms: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}]
                    queryParams: {
                        // Add url query string when load gtm.js with GTM ID (optional)
                        gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
                        gtm_preview: 'env-4',
                        gtm_cookies_win: 'x'
                    },
                    defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
                    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
                    debug: false, // Whether or not display console logs debugs (optional)
                    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
                    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
                    ignoredViews: [], // Don't trigger events for specified router names (case insensitive) (optional)
                    trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
                });
            } else {
                state.gtmId = null
            }
        },
        resetDayArray: (state, payload) => {
            if (payload === 'all') {
                state.days = [
                    {
                        id: 1,
                        dayNr: '0',
                        day: 'zondag',
                        enabled: false,
                        timeStart: null,
                        timeEnd: null
                    },
                    {
                        id: 2,
                        dayNr: '1',
                        day: 'maandag',
                        enabled: false,
                        timeStart: null,
                        timeEnd: null
                    },
                    {
                        id: 3,
                        dayNr: '2',
                        day: 'dinsdag',
                        enabled: false,
                        timeStart: null,
                        timeEnd: null
                    },
                    {
                        id: 4,
                        dayNr: '3',
                        day: 'woensdag',
                        enabled: false,
                        timeStart: null,
                        timeEnd: null
                    },
                    {
                        id: 5,
                        dayNr: '4',
                        day: 'donderdag',
                        enabled: false,
                        timeStart: null,
                        timeEnd: null
                    },
                    {
                        id: 6,
                        dayNr: '5',
                        day: 'vrijdag',
                        enabled: false,
                        timeStart: null,
                        timeEnd: null
                    },
                    {
                        id: 7,
                        dayNr: '6',
                        day: 'zaterdag',
                        enabled: false,
                        timeStart: null,
                        timeEnd: null
                    }
                ]
            }
        },
        setRedirectUrl: (state, payload) => {
            if (payload) {
                state.redirectUrl = payload
            } else {
                state.redirectUrl = false
            }
        },
        setHeaderInfo: (state, payload) => {
            if (payload) {
                state.headerInfo = payload
            } else {
                state.headerInfo = []
            }
        },
        setLogo: (state, payload) => {
            if (payload) {
                state.logo = payload
            } else {
                state.logo = null
            }
        },
        setAccountLess: (state, payload) => {
            if (payload) {
                state.accountLess = payload
            } else {
                state.accountLess = false
            }
        },
        setShowDescriptionPrice: (state, payload) => {
            if (payload) {
                state.showDescriptionPrice = payload
            } else {
                state.showDescriptionPrice = false
            }
        },
        setShowEstablishmentAddress: (state, payload) => {
            if (payload) {
                state.showEstablishmentAddress = payload
            } else {
                state.showEstablishmentAddress = false
            }
        },
        setHzzEntry: (state, payload) => {
            if (payload) {
                state.hzzEntry = payload
            } else {
                state.hzzEntry = false
            }
        },
        setHzzData: (state, payload) => {
            if (payload) {
                state.hzzData = payload
            } else {
                state.hzzData = []
            }
        },
        setRescheduleData: (state, payload) => {
            if (payload) {
                state.rescheduleData = payload
            } else {
                state.rescheduleData = []
            }
        },
        setIntakeEnabled: (state, payload) => {
            if (payload) {
                state.intakeEnabled = payload
            } else {
                state.intakeEnabled = false
            }
        },
        setIntakeText: (state, payload) => {
            if (payload) {
                state.intakeText = payload
            } else {
                state.intakeText = []
            }
        },
        setMutationSettings: (state, payload) => {
            if (payload) {
                state.mutationSettings = payload
            } else {
                state.mutationSettings = []
            }
        },
        setReschedule: (state, payload) => {
            if (payload) {
                state.reschedule = payload
            } else {
                state.reschedule = false
            }
        },
        setColors: (state, payload) => {
            if (payload) {
                state.colors = payload
            } else {
                state.colors = []
            }
        },
        setWeeksAhead: (state, payload) => {
            if (payload) {
                state.weeksAhead = payload
            } else {
                state.weeksAhead = 3
            }
        },
        setMinHoursAhead: (state, payload) => {
            if (payload) {
                state.minHoursAhead = payload
            } else {
                state.minHoursAhead = 3
            }
        },
        setAppointmentOptionsReady: (state, payload) => {
            if (payload) {
                state.appointmentOptionsReady = payload
            } else {
                state.appointmentOptionsReady = false
            }
        },
        setLogToken: (state, payload) => {
            if (payload) {
                state.logToken = payload
            } else {
                state.logToken = false
            }
        },
        setPageHistory: (state, payload) => {
            if (payload) {
                state.pageHistory.push(payload)
            } else {
                state.pageHistory = []
            }
        },
        setTreatmentData: (state, payload) => {
            if (payload) {
                state.treatmentData = payload
            } else {
                state.treatmentData = []
            }
        },
        setMultipleInstances: (state, payload) => {
            if (payload) {
                state.multipleInstances = payload
            } else {
                state.multipleInstances = false
            }
        },
        setPatientRemarkEnabled: (state, payload) => {
            if (payload) {
                state.patientRemarkEnabled = payload
            } else {
                state.patientRemarkEnabled = false
            }
        },
        setPatientRemark: (state, payload) => {
            if (payload) {
                state.patientRemark = payload
            } else {
                state.patientRemark = null
            }
        },
        setNumberOfOptions: (state, payload) => {
            if (payload) {
                state.numberOfOptions = payload
            } else {
                state.numberOfOptions = 0
            }
        }
    },
    actions: {}
});

