<template>
    <div class="col-12 col-sm-9">
        <div class="header-bar">
            <h4>Kies uw afspraak</h4>
        </div>
        <div class="card-body">
            <div
                v-if="appointmentOptions"
                class="d-flex justify-content-center"
            >
                <b-form-select
                    id="select-appointment-date"
                    v-model="selectedDate"
                    class="form-control"
                    style="width: 35%"
                >
                    <template
                        v-slot:first
                    >
                        <b-form-select-option
                            :value="null"
                            disabled
                        >
                            Selecteer een dag
                        </b-form-select-option>
                    </template>
                    <option
                        v-for="(dateOption, index) in appointmentOptions"
                        v-bind:key="index"
                        :value="index"
                    >
                        {{ index | moment("dddd, DD-MM-YYYY") }}
                    </option>
                </b-form-select>
            </div>
            <div v-else><b>{{ selectedDate | moment("dddd, DD-MM-YYYY") }}</b></div>
        </div>
        <div class="card-body justify-content-center d-flex">
            <div v-if="optionsByDate.length > 0">
                <table
                    v-for="(option, index) in optionsByDate"
                    v-bind:key="index"
                >
                    <tr v-if="option.idEpisode != null || intake">
                        <td>
                            <input
                                type="radio"
                                :id="index"
                                :value="option"
                                v-model="selectedAppointment"
                            >
                        </td>
                        <td>{{ option.startTime }}</td>
                        <td> -</td>
                        <td>{{ option.endTime }}</td>
                        <td v-if="option.employee">({{ option.employee }})</td>
                    </tr>
                </table>
                <span
                    class="error"
                    v-if="error"
                >Selecteer een afspraak</span>
            </div>
        </div>
        <div v-if="selectedAppointment && showPatientRemark">
            <b-form-textarea
                id="additional-information"
                placeholder="Heeft u aanvullende informatie?"
                size="lg"
                v-model="patientRemark"
            ></b-form-textarea>
        </div>
        <b-row>
            <div
                v-if="numberOfOptions < 1 && appointmentOptionsReady"
                class="card-body justify-content-center d-flex"
            >
              <p>Helaas kan er geen afspraak gepland worden. <br><br> Ga terug om andere voorkeuren op te geven of neem contact met ons op om te kijken of we samen een geschikte tijd vinden voor jouw afspraak.</p>
            </div>
            <div
                v-if="scheduleError"
                class="card-body justify-content-center d-flex"
            >
                <p>De gemaakt datum en tijd zijn helaas al ingepland.</p>
                <br><br>
                <p>Maak een andere keuze of ga terug.</p>
            </div>
        </b-row>
        <br><br>
        <b-row>
            <b-col>
                <div
                    class="d-flex justify-content-center"
                    v-if="this.$store.state.lastRoute !== 'register'"
                >
                    <GoBack></GoBack>
                </div>
                <div
                    class="d-flex justify-content-center"
                    v-else
                >
                    <button
                        id="backToPortal"
                        v-on:click="this.goBack"
                        class="btn btn-primary"
                    >
                        Terug naar portal
                    </button>
                </div>
            </b-col>
            <b-col v-if="numberOfOptions >= 1">
                <div>
                    <div class="d-flex justify-content-center">
                        <button
                            id="plan-btn"
                            v-on:click="submit"
                            class="btn btn-primary"
                        >
                            Bevestig afspraak
                        </button>
                    </div>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>

import appointmentCalls from "../mixins/appointmentCalls";
import GoBack from "./GoBack";
import {required} from "vuelidate";
import EpisodeCalls from "../mixins/EpisodeCalls";

export default {
    components: {GoBack},
    mixins: [appointmentCalls, EpisodeCalls],
    data() {
        return {
            selectedOption: null,
            options: [],
            selectedDate: null,
            optionsByDate: [],
            error: false,
            scheduleError: false,
            clientNumber: null,
            tokenString: '',
            message: null,
            patientRemark: null,
            numberOfOptions: 0
        };
    },
    name: "Appointment",
    validations: {
        select: {
            required
        }
    },
    created() {
        if (this.token) {
            this.tokenString = '&api-token=' + this.$store.state.token
        }
        this.getClientNumber()
        if (this.isIntake) {
            this.checkForExistingOpenEpisode()
        }
        this.getNumberOfOptions()
    },
    methods: {
        toPortal() {
            this.$router.push({
                name: 'portal',
                params: {practiceHash: this.$store.state.practiceHash}
            })
        },
        submit() {
            if (!this.selectedAppointment) {
                this.error = true
            } else {
                this.$store.commit('setSelectedAppointment', this.selectedAppointment)
                this.planAppointment()
            }
        },
        planAppointment() {
            this.scheduleError = false
            if (this.idEpisode) {
                this.scheduleAppointment(this.idEpisode)
            } else {
                if (this.token && !this.$store.state.tempIdPatient) {
                    if (this.$store.state.intake) {
                        this.createEpisode()
                    } else {
                        this.idEpisode = this.selectedAppointment.idEpisode
                        this.scheduleAppointment(this.idEpisode);
                    }
                } else if (this.$store.state.tempIdPatient) {
                    this.$router.push({
                        name: 'register',
                        params: {practiceHash: this.$store.state.practiceHash}
                    })
                }
            }
        },
        goBack() {
            this.$store.commit('setLastRoute', null)
            this.$router.push({name: 'portal', params: {practiceHash: this.$store.state.practiceHash}})
        },
        getNumberOfOptions() {
            this.numberOfOptions = this.$store.getters.getNumberOfOptions
            if (this.numberOfOptions === 1) {
                this.selectedDate = Object.keys(this.appointmentOptions)[0]
            }
        }
    },
    computed: {
        token() {
            return this.$store.state.token
        },
        practiceCode() {
            return this.$store.state.practiceCode
        },
        appointmentOptions() {
            return this.$store.state.appointmentOptions
        },
        idTempPatient() {
            return this.$store.getters.getTempIdPatient
        },
        appointmentOptionsReady() {
            return this.$store.getters.getAppointmentOptionsReady
        },
        showPatientRemark() {
            return this.$store.getters.getPatientRemarkEnabled
        }
    },
    watch: {
        selectedDate: function () {
            this.selectedDate = this.$moment(this.selectedDate).format('YYYY-MM-DD')
            this.optionsByDate = this.appointmentOptions[this.selectedDate]
        },
        patientRemark: function () {
            this.$store.commit('setPatientRemark', this.patientRemark)
        }
    }
}
</script>

<style scoped>

</style>