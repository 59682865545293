import axios from "axios";

export default {
    created() {
        this.getClientNumber()
    },
    data() {
        return {
            clientNumber: null,
            idEpisode: null,
            idPatient: this.$store.state.idPatient
        }
    },
    methods: {
        getEpisodeData() {
            return {
                patient: parseInt(this.idPatient),
                practice: parseInt(this.clientNumber),
                chiefPractitioner: parseInt(this.selectedTreatment.idEmployee),
                disease: parseInt(this.selectedTreatment.idCondition),
                treatment: parseInt(this.selectedTreatment.idIntakeTreatment),
                invoiceInsurer: 0,
                logToken: this.logToken || null
            }
        },
        createEpisode() {
            axios.post(process.env.VUE_APP_API_URL + 'episode?practice=' + this.practiceCode + '&api-token=' + this.$store.state.token,
                this.getEpisodeData()
            ).then((response) => {
                this.idEpisode = response.data.id
                this.scheduleAppointment(this.idEpisode);
            })
        },
        createAccountLessEpisode() {
            axios.post(process.env.VUE_APP_API_URL + 'accountLessEpisode?practice=' + this.practiceCode,
                this.getEpisodeData()
            ).then((response) => {
                this.idEpisode = response.data.idEpisode
                this.scheduleAppointment(this.idEpisode);
            })
        },
        checkForExistingOpenEpisode() {
            if (!this.idEpisode) {
                let vm = this
                return axios.get(process.env.VUE_APP_API_URL + 'checkEpisodes/?practice=' + this.practiceCode + this.tokenString,
                    {
                        params: {
                            patientId: this.idPatient,
                            disease: parseInt(this.selectedTreatment.idCondition),
                            treatment: parseInt(this.selectedTreatment.idIntakeTreatment),
                            logToken: this.logToken || null
                        }
                    }).then((response) => {
                    if (response.data.existingEpisode) {
                        vm.idEpisode = response.data.idEpisode
                        return vm.idEpisode
                    } else {
                        return false
                    }
                })
            }
        },
        getClientNumber() {
            axios.get(process.env.VUE_APP_API_URL + 'getClientNumber/?practice=' + this.practiceCode, {
                params: {logToken: this.logToken}
            }).then((response) => {
                this.clientNumber = response.data.clientNumber;
            })
        },
    },
    computed: {
        selectedTreatment() {
            return this.$store.getters.getSelectedTreatment
        },
        logToken() {
            return this.$store.getters.getLogToken
        }
    }
}