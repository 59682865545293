import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router';
import {routes} from './router/routes';
import 'vue-search-select/dist/VueSearchSelect.css'
import vSelect from 'vue-select'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import {store} from "./store/Store";
import axios from "axios";
import VueAxios from 'vue-axios'
//vuelidate
import Vuelidate from 'vuelidate'

//Sentry
import * as Sentry from '@sentry/browser';
import {Vue as VueIntegration} from '@sentry/integrations';

//css
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

//CalendarPlugin
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/nl';

//mixins
import Api from "./mixins/Api";
//own Component
import LogOffButton from "@/components/Button/LogOffButton";
// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

//Sentry
Sentry.init({
    dsn: 'https://a62dee9ef9bb4bada4509ccab7c6f36a@sentry.novict.nl/3',
    integrations: [new VueIntegration({Vue, attachProps: true, logErrors: true})],
});

//Axios
Vue.use(VueAxios, axios)

//Router
Vue.use(VueRouter);

//calendar
Vue.component('vue2-datepicker', DatePicker)

Vue.component('LogOffButton', LogOffButton)

//moment
const moment = require('moment')
require('moment/locale/nl')
Vue.use(require('vue-moment'), {
    moment
})
Vue.component('v-select', vSelect);
Vue.mixin(Api)

export const router = new VueRouter({
    base: process.env.VUE_APP_BASE,
    routes,
    mode: 'history'
});

Vue.use(Vuelidate)


new Vue({
    store,
    router,
    render: h => h(App),
}).$mount('#app');