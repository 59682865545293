import axios from "axios";

export default {
    methods: {
        getTreatmentById(treatmentId) {
            return axios.get(process.env.VUE_APP_API_URL + 'treatment/' + treatmentId + '/', {
                params: {
                    practice: this.practiceCode,
                    logToken: this.$store.getters.getLogToken
                }
            }).then((response) => {
                return response.data
            })
        },
        getTempId() {
            return axios.get(process.env.VUE_APP_API_URL + 'tempId/', {
                params: {
                    practice: this.practiceCode,
                    logToken: this.$store.getters.getLogToken
                }
            }).then((response) => {
                if (response.data.tempId === 'success') {
                    this.$store.state.tempIdPatient = response.data.tempIdPatient
                    this.$store.state.idPatient = response.data.tempIdPatient
                    this.$store.state.token = response.data.apiToken
                    this.$store.state.tempToken = response.data.apiToken
                }
            })
        },
        logOff() {
            this.$store.commit('setToken', null)
            sessionStorage.clear();
            if (this.$store.state.redirectUrl !== false) {
                window.location.href = this.$store.state.redirectUrl
            } else {
                this.$router.push({
                    name: 'home',
                    params: {practiceHash: this.$route.params.practiceHash}
                });
            }
        },
        getClientId() {
            axios.post(process.env.VUE_APP_API_URL + 'practiceCode',
                {
                    hash: this.$route.params.practiceHash,
                    logToken: this.$store.getters.getLogToken || null
                }
            ).then((response) => {
                this.practiceCode = response.data.practiceCode.practiceCode
                this.$store.commit('setPracticeCode', this.practiceCode)
                this.setHzzInfo()
            })
        }
    }
}