<template>
    <div>
        <form
            id="loginform"
            class="form-horizontal"
            role="form"
        >
            <div class="form-group">
                <b-form-input
                    id="login-username"
                    type="email"
                    class="form-control"
                    :class="{ 'form-group--error': $v.email.$error } "
                    name="username"
                    v-model="$v.email.$model"
                    @input="setEmail($event)"
                    placeholder="email"
                />
                <span
                    v-if="msg.email"
                    id="wrong-email"
                    style="color: red"
                >
          {{ msg.email }}
        </span>
            </div>
            <div class="form-group">
                <b-form-input
                    id="login-password"
                    type="password"
                    class="form-control"
                    :class="{ 'form-group--error': $v.password.$error }"
                    name="password"
                    v-model="$v.password.$model"
                    @input="setPassword($event)"
                    @keyup.enter="logIn"
                    placeholder="wachtwoord"
                />
                <span
                    class="error"
                    v-if="!$v.password.required && error"
                >
          Voer correct wachtwoord in
        </span>
            </div>
            <span
                v-if="loginFail"
                id="login-fail-message"
                style="color: red"
            >{{ msg.loginError }}</span>
            <div class="form-group row">
                <div class=" col-3">
                    <a
                        id="btn-login"
                        href="#"
                        v-on:click="submit"
                        class="btn btn-primary"
                    >Login
                    </a>
                </div>
                <div
                    class=" col-9 d-flex align-items-center justify-content-end"
                    style="font-size: 80%; cursor: pointer; padding-top: 5px;"
                >
                    <a
                        id="reset-password"
                        v-on:click="passwordModalVisible = true"
                    >Nieuw wachtwoord aanvragen
                    </a>
                </div>
            </div>
        </form>

        <modal v-show="verificationModalVisible">
            <h3
                slot="header"
                class="modal-title"
            >
                Verificatie
            </h3>
            <button
                slot="header"
                type="button"
                class="close"
                @click="verificationModalVisible = false"
            >
                <span aria-hidden="true">&times;</span>
            </button>
            <div
                slot="body"
                style="padding: 20px"
            >
                <p>Er is een verificatie code naar u verstuurd. Voer deze hier in om verder te gaan.</p>
                <label for="verification-code"></label>
                <input
                    id="verification-code"
                    type="text"
                    class="form-control"
                    name="verification-code"
                    placeholder="Voer de ontvangen code in"
                >
            </div>
            <div slot="footer">
                <button
                    type="button"
                    class="btn btn-outline-secondary"
                    v-on:click="isModalVisible = false"
                >Annuleren
                </button>
            </div>
        </modal>
        <modal
            id="password-modal"
            v-show="passwordModalVisible"
        >
            <h4
                slot="header"
                class="modal-title"
            >
                Nieuw wachtwoord aanvragen
            </h4>
            <button
                slot="header"
                type="button"
                class="close"
                @click="passwordModalVisible = false"
            >
                <span aria-hidden="true">&times;</span>
            </button>
            <div
                slot="body"
                style="padding: 20px"
            >
                <div v-if="!passwordReset">
                    <input
                        id="password-email"
                        type="text"
                        class="form-control"
                        v-model="passwordEmail"
                        name="password-email"
                        placeholder="E-mail"
                    >
                  <small id="emailText" class="form-text text-muted">Voer het e-mailadres in van het account dat u wilt herstellen.</small>
                </div>
              <br>
              <div v-if="!passwordReset">
                <vue2-datepicker
                    id="birthdate-email"
                    v-model="birthDateEmail"
                    :format="'DD-MM-YYYY'"
                    placeholder="Geboortedatum"
                    style="width: 100%"
                >
                </vue2-datepicker>
                <small id="birthDateText" class="form-text text-muted">Voer de geboortedatum in van het account dat u wilt herstellen.</small>
              </div>
                <div
                    id="reset-message"
                    v-if="passwordReset"
                >
                    <p>{{ resetMessage }}</p>
                </div>
            </div>
            <div
                slot="footer"
                v-if="!passwordReset"
            >
                <button
                    type="button"
                    id="reset-password-btn"
                    class="btn btn-primary"
                    v-on:click="resetPassword"
                >
                    Vraag wachtwoord resetlink aan
                </button>
            </div>
            <div
                slot="footer"
                v-if="passwordReset"
            >
                <button
                    type="button"
                    class="btn btn-outline-secondary"
                    v-on:click="back"
                >Terug

                </button>
            </div>
        </modal>
    </div>
</template>

<script>
import Modal from "./Modal";
import axios from "axios";
import {email, required} from 'vuelidate/lib/validators'

export default {
    name: 'LoginComponent',
    components: {Modal},
    data() {
        return {
            email: null,
            password: null,
            verificationModalVisible: false,
            passwordModalVisible: false,
            passwordEmail: null,
            birthDateEmail: null,
            passwordReset: false,
            msg: [],
            loginFail: false,
            error: false,
            resetMessage: ""
        }
    },
    validations: {
        email: {
            required, email
        },
        password: {
            required
        }
    },
    methods: {
        setEmail(value) {
            this.email = value
        },
        setPassword(value) {
            this.password = value
        },
        submit() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.error = true
            } else {
                this.logIn()
            }
        },
        logIn() {
            axios.post(process.env.VUE_APP_API_URL + 'login?practice=' + this.practiceCode,
                {
                    email: this.email,
                    password: this.password,
                    logToken: this.$store.getters.getLogToken || null
                })
                .then((response) => {
                    let result = response.data
                    if (result.login === 'success') {
                        this.$store.commit('setToken', result.apiToken)
                        this.$store.commit('setIdPatient', result.patientId)
                        this.$store.commit('setPatientEmail', this.email)
                        if (result.multiple === true){
                          this.$store.commit('setMultipleInstances', true)
                        } else {
                          this.$store.commit('setMultipleInstances', false)
                        }
                        this.$router.push({
                            name: 'portal',
                            params: {practiceHash: this.$store.state.practiceHash}
                        })
                    } else if (result.error['message'] === 'login information was incorrect') {
                      this.loginFail = true
                        this.msg['loginError'] = "Inlog gegevens incorrect, controleer uw email en wachtwoord."
                    }
                })
        },
        resetPassword() {
            axios.post(process.env.VUE_APP_API_URL + 'resetpassword?practice=' + this.practiceCode, {
                email: this.passwordEmail,
                birthdate: this.$moment(this.birthDateEmail).format('YYYY-MM-DD'),
                practiceHash: this.practiceHash,
                logToken: this.$store.getters.getLogToken || null
            }).then((response) => {
                if (response.data === 'success') {
                    this.resetMessage = "Indien het opgegeven e-mailadres bij ons bekend is, wordt er een e-mail met daarin een herstellink verzonden." +
                        "\n\nOntvangt u geen e-mail, neem dan contact op met de praktijk."
                }
                this.passwordReset = true
            }).catch(function () {
            })
        },
        back() {
            this.passwordReset = false
            this.passwordEmail = null
            this.birthDateEmail = null
            this.passwordModalVisible = false
        },
        validateEmail(value) {
            if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
                this.msg['email'] = '';
            } else {
                this.msg['email'] = 'Ongeldig email adres';
            }
        }
    },
    watch: {
        email(value) {
            this.email = value;
            this.validateEmail(value);
        }
    },
    computed: {
        practiceHash() {
            return this.$store.state.practiceHash
        },
        practiceCode() {
            return this.$store.state.practiceCode
        }
    },
}
</script>

<style>
.form-horizontal {
    padding-top: 20px;
}

.form-group {
    margin-bottom: 25px;
}
#birthDateText{
  font-size: 12px;
}
</style>