<template>
    <div class="col-12 col-sm-9">
        <div class="header-bar">
            <h4>Kies een behandeling en een vestiging</h4>
        </div>
        <div v-if="wait">
            <Loader text="Opties ophalen"></Loader>
        </div>
        <div v-else>
            <div
                id="selectedTreatment-2"
                class="card-body "
                v-if="(token && !tempToken) && !intake"
            >
                <div v-if="patientEpisodes.length > 0">
                    <div v-if="patientEpisodes.length > 1 && !rescheduleData.idEpisode">
                        <b-form-select
                            v-model="$v.selectedEpisode.$model"
                            id="select-treatment"
                            class="form-control"
                            @input="setEpisode($event)"
                        >
                            <template
                                v-slot:first
                                v-if="patientEpisodes.length > 1 "
                            >
                                <b-form-select-option
                                    :value="null"
                                    disabled
                                >Selecteer een aandoening
                                </b-form-select-option>
                            </template>
                            <option
                                v-for="(episode, index) in patientEpisodes"
                                v-bind:key="index"
                                :value="episode"
                                :selected="episode.id === rescheduleData.idEpisode"
                            >
                              {{ getDiseaseName(episode.disease) }}
                            </option>
                        </b-form-select>
                        <span
                            class="error"
                            v-if="!$v.selectedEpisode.required && error"
                        >Kies een behandeling</span>
                    </div>
                    <div v-else>
                        <label class="form-control"> {{ getDiseaseName(this.selectedEpisode.disease) }}</label>
                    </div>
                </div>
                <div
                    class="d-flex justify-content-center"
                    v-else
                >
                    <p>Er is geen lopende behandeling. Hierdoor kunt u geen vervolg afspraak plannen. <br> Ga terug om een
                      nieuw behandeltraject te starten.
                    </p>
                </div>
                <div v-if="selectedEpisode && showDescriptionPrice">
                    <div class="list-group">
                        <div class="list-group-item description">
                            <div class="d-flex w-100 justify-content-between">
                              <h5 class="mb-1">{{ getDiseaseName(this.selectedEpisode.disease) }}</h5>
                              <small v-if="selectedEpisode.treatmentPrice && this.selectedEpisode.treatmentPrice > 0">
                                 &euro; {{ this.selectedEpisode.treatmentPrice }} </small>
                              <small v-else-if="treatmentData">&euro; {{ treatmentData.price }} </small>
                            </div>
                            <small v-if="selectedEpisode.treatmentTime && this.getMinutes(selectedEpisode.treatmentTime) > 0">
                               {{ this.getMinutes(selectedEpisode.treatmentTime) }} minuten</small>
                            <small v-else-if="treatmentData">{{ treatmentData.time }} minuten</small>
                        </div>
                    </div>
                </div>
            </div>

            <div
                id="selectedIntake-2"
                class="card-body "
                v-if="(intake)"
            >
                <div v-if="!noIntakeOptions">
                    <div v-if="intakeOptions.length > 1 && (!hzzEntry || !hzzData.idIntakeTreatment)">
                      <b-form-select
                          v-model="$v.selectedTreatment.$model"
                          class="form-control"
                          id="select-treatment"
                          @input="setTreatment($event)"
                      >
                        <template v-slot:first>
                          <b-form-select-option
                              :value="null"
                              disabled
                          >Kies een behandeling
                          </b-form-select-option>
                        </template>
                        <option
                            v-for="(intake, index) in intakeOptions"
                            v-bind:key="index"
                            :value="intake"
                        >
                          {{ intake.displayName }}
                        </option>
                      </b-form-select>
                      <span
                          class="error"
                          v-if="!$v.selectedTreatment.required && error"
                      >Kies een behandeling</span>
                    </div>
                    <div v-else>
                        <label
                            class="form-control"
                            v-if="selected"
                        > {{ this.selectedTreatment.displayName }}
                        </label>
                    </div>
                    <div v-if="selectedTreatment && showDescriptionPrice">
                        <div class="list-group">
                            <div class="list-group-item description">
                                <div class="d-flex w-100 justify-content-between ">
                                    <h5 class="mb-1">{{ selectedTreatment.displayName }}</h5>
                                    <small>
                                      {{ selectedTreatment.price > '0.00' ? '&euro;' + selectedTreatment.price : 'Gratis'
                                      }} </small>
                                  </div>
                                <p class="mb-1">{{ selectedTreatment.description }}</p>
                                <small v-if="treatmentData">{{ treatmentData.time }} minuten</small>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="noIntakeOptions">
                    <p>Voor deze praktijk zijn nog geen behandel opties toegevoegd, neem contact op met de praktijk om een
                      behandeling te plannen.
                    </p>
                </div>
            </div>

            <div v-if="!noEstablishment">
                <div
                    v-if="postalCodeCheck && showPostalCode"
                    class="card-body justify-content-center d-flex"
                >
                    <b-row>
                        <b-form-group>
                            <b-form-input
                                type="text"
                                class="form-control"
                                id="password-check"
                                maxlength="6"
                                @input="setPostalCode($event)"
                                placeholder="Voer uw postcode in (1111AA)"
                            >
                            </b-form-input>
                        </b-form-group>
                    </b-row>
                </div>
                <div
                    id="selectedEstablishment-2"
                    class="card-body"
                    v-if="(patientEpisodes.length > 0 || (intakeOptions.length > 0))"
                >
                    <div
                        v-if="disableInput"
                        class="d-flex justify-content-center"
                    >
                        <p>Om een selectie te maken van de dichtstbijzijnde vestiging vragen we u om uw postcode in te
                          voeren.
                        </p>
                    </div>
                    <div v-if="establishmentsByTreatment.length > 1 && !hzzEntry">
                        <b-form-select
                            v-model="$v.selectedEstablishment.$model"
                            class="form-control"
                            data-cy="select-establishment"
                            :disabled="disableInput === true"
                            @input="setEstablishment($event)"
                        >
                            <b-form-select-option
                                :value="null"
                                disabled
                            > Selecteer een vestiging
                            </b-form-select-option>
                            <option
                                v-for="establishment in establishmentsByTreatment"
                                v-bind:key="establishment.id"
                                :value="establishment"
                                :selected="establishment.id === rescheduleData.idEstablishment"
                            >
                              {{ establishment.name }}
                            </option>
                          </b-form-select>
                    </div>
                    <div v-else>
                        <div v-if="selectedEstablishment">
                          <h5>Vestiging</h5>
                          <label
                              class="form-control"
                              data-cy="select-establishment-label"
                          >
                            {{ this.selectedEstablishment.name }}
                          </label>
                        </div>
                    </div>
                    <div v-if="selectedEstablishment && showEstablishmentAddress">
                        <div class="list-group">
                              <div class="list-group-item description">
                                  <div class="d-flex w-100 justify-content-between ">
                                      <h6 class="mb-1">{{ selectedEstablishment.name }}</h6>
                                  </div>
                                  <p class="mb-1">
                                      {{ selectedEstablishment.street }} {{ selectedEstablishment.number }}
                                      {{ selectedEstablishment.numberAddition }} <br> {{ selectedEstablishment.postalCode }}
                                      {{ selectedEstablishment.city }} <br> {{ selectedEstablishment.phoneNumber }}
                                  </p>
                              </div>
                        </div>
                    </div>
                    <span
                        class="error"
                        v-if="!$v.selectedEstablishment.required && error"
                    >
                        Kies een vestiging
                    </span>
                </div>
                <br>
                <div class="card-body d-flex justify-content-center">
                    <b-col>
                      <GoBack></GoBack>
                    </b-col>
                    <b-col>
                        <div class="d-flex justify-content-center">
                            <button
                                id="continue-btn"
                                class="btn btn-primary"
                                @click="checkData"
                            >Ga verder
                            </button>
                        </div>
                    </b-col>
                </div>
            </div>
            <div v-else>
                <p>Er kon geen passende vestiging gevonden worden.</p>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import GoBack from "./GoBack";
import {required} from "vuelidate/lib/validators";
import appointmentOption from '../mixins/appointmentOption';
import Calls from "../mixins/calls";
import Loader from "@/components/Loader/Loader.vue";

export default {
    components: {Loader, GoBack},
    mixins: [Calls, appointmentOption],
    created() {
        this.getDaysOpenClosed()
        if (this.$store.getters.getIdPatient == null) {
            this.getTempId()
        }
        if (this.$route.params.treatment !== undefined) {
            this.getClientId()
            this.idPreselectedTreatment = this.$route.params.treatment
            this.$store.commit('setIntake', true)
        }
        this.intake = this.$store.getters.getIntake
        this.hzzEntry = this.$store.getters.getHzzEntry
        if (!this.$store.state.postalCodeCheck) {
            this.getEstablishments();
        } else {
            this.checkPostalCode();
        }
        if (!this.intake) {
            this.tokenString = '&api-token=' + this.$store.state.token
            if (this.token) {
                this.getDiseases();
            }
        } else {
            this.getIntakeOptions();
        }
    },
    data() {
        return {
            selected: false,
            selectedEstablishment: null,
            selectedEpisode: null,
            establishments: [],
            patientEpisodes: [],
            intakeOptions: [],
            activeIntakeOptions: [],
            activeEstablishments: [],
            selectedTreatment: null,
            diseases: [],
            tokenString: '',
            error: false,
            noIntakeOptions: false,
            noEstablishment: false,
            postalCode: this.$store.state.patientPostalCode,
            treatmentsByEstablishment: [],
            disableInput: false,
            showPostalCode: false,
            oneOption: false,
            establishmentsByTreatment: [],
            treatmentData: null,
            selectedHzzTreatment: null,
            hzzEntry: false,
            intake: false,
            idPreselectedTreatment: null,
            days: this.$store.state.days,
            wait: false,
        }
    },
    validations: {
        selectedEpisode: {
            required,
        },
        selectedEstablishment: {
            required,
        },
        selectedTreatment: {
            required,
        }
    },
    methods: {
        setData: function () {
          let duration
          let date = new Date()
          let treatmentId
          if (!this.intake) {
            treatmentId = this.selectedEpisode.treatment
          } else {
            treatmentId = this.selectedTreatment.idIntakeTreatment
          }

          this.getTreatmentById(treatmentId).then(treatment => {
            duration = this.$moment(date.setHours(0, treatment.time, 0, 0)).format('HH:mm')
            if (!this.intake && this.selectedEpisode.treatmentTime) {
              duration = this.$moment(this.selectedEpisode.treatmentTime, "HH:mm").format("HH:mm")
            }
            if (!this.intake) {
              this.treatmentArray = [{
                episode: this.selectedEpisode.id,
                disease: this.selectedEpisode.disease,
                treatment: this.selectedEpisode.treatment,
                duration: duration,
                requiredEquipment: this.selectedEpisode.equipment
              }]
            } else if (this.intake) {
              this.treatmentArray = [{
                disease: this.selectedTreatment.idCondition,
                treatment: this.selectedTreatment.idIntakeTreatment,
                duration: duration,
              }]
            }

            let i = 0
            for (i; i < this.days.length; i++) {
              this.days[i] = {
                id: this.days[i]['id'],
                dayNr: this.days[i]['dayNr'],
                day: this.days[i]['day'],
                enabled: true,
                timeStart: '06:00:00',
                timeEnd: '23:00:00'
              }
            }

            for (i; i < this.daysOpenClosed.length; i++) {
              if (!this.daysOpenClosed[i].show_in_appointmentsystem) {
                this.days[this.daysOpenClosed[i].day_number].enabled = false;
              }
            }
            this.$store.commit('setDays', this.days)

            this.dataArray = {
              settlement: this.selectedEstablishment.id,
              treatments: this.treatmentArray,
              days: this.days,
              preferredDate: this.specificDate,
            }
            this.$store.commit('setPostArray', this.dataArray)
            this.getAppointmentOptions()
          })
        },
        getDaysOpenClosed() {
          axios.get(process.env.VUE_APP_API_URL + 'daysOpenClosed/?practice=' + this.practiceCode, {
            params: {logToken: this.$store.getters.getLogToken}
          }).then((response) => {
            let results = response.data;
            this.daysOpenClosed = JSON.parse(JSON.stringify(results.value))
            this.$store.commit('setDaysOpenClosed', this.daysOpenClosed)
          })
        },
        submit() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.error = true
            } else {
                this.checkData()
            }
        },
        setEstablishment(value) {
            this.selectedEstablishment = value
            this.$v.selectedEstablishment.$touch()
        },
        setEpisode(value) {
            this.selectedEpisode = value
            this.$v.selectedEpisode.$touch()
        },
        setTreatment(value) {
            this.selectedTreatment = value
            this.$v.selectedTreatment.$touch()
        },
        setPostalCode(value) {
            if (value.length === 6) {
                this.postalCode = value
                this.$store.commit('setPatientPostalCode', this.postalCode)
                this.getEstablishmentsByPostalCode(this.postalCode);
            }
        },
        getTreatmentData(treatmentId) {
            this.getTreatmentById(treatmentId).then(treatment => {
                this.$store.commit('setTreatmentData', treatment)
                this.treatmentData = treatment
            })
        },
        checkData() {
            if (this.selectedEstablishment && (this.selectedEpisode || this.selectedTreatment)) {
                this.setData()
            }
        },
        getEstablishments() {
            if (this.$store.state.establishments.length >= 1) {
                this.establishments = this.$store.state.establishments
            } else {
                axios.get(process.env.VUE_APP_API_URL + 'establishment/?practice=' + this.practiceCode + this.tokenString, {
                    params: {logToken: this.logToken}
                }).then((response) => {
                    this.establishments = response.data;
                    let vm = this
                    this.establishments.forEach(function (establishment) {
                        if (establishment.active === '1' && establishment.visibleInAs === '1') {
                            vm.activeEstablishments.push(establishment);
                        }
                    })
                    this.establishments = vm.activeEstablishments
                })
            }
        },
        getEpisodes() {
            axios.get(process.env.VUE_APP_API_URL + 'patient/' + this.idPatient + '/episode/?practice=' + this.practiceCode + '&api-token=' + this.$store.state.token, {
                params: {logToken: this.logToken}
            }).then((response) => {
                this.patientEpisodes = response.data;
                if (this.rescheduleData.idEpisode) {
                    let vm = this
                    this.patientEpisodes.filter(function (episode) {
                        if (vm.rescheduleData.idEpisode === episode.id) {
                            vm.selectedEpisode = episode
                        }
                    })
                } else if (this.patientEpisodes.length === 1) {
                    this.selectedEpisode = this.patientEpisodes[0]
                }
            })
        },
        getDiseases() {
            axios.get(process.env.VUE_APP_API_URL + 'disease/?practice=' + this.practiceCode + '&api-token=' + this.$store.state.token, {
                params: {logToken: this.logToken}
            }).then((response) => {
                this.diseases = response.data;
            })
        },
        getDiseaseName(idDisease) {
            return this.diseases.filter(function (disease) {
                return disease.id === idDisease
            })[0].name
        },
        getIntakeOptions() {
            axios.get(process.env.VUE_APP_API_URL + 'intake-option/?practice=' + this.practiceCode + this.tokenString, {
                params: {logToken: this.logToken}
            }).then((response) => {
                let results = response.data;
                let vm = this
                if (results.length > 0) {
                    results.forEach(function (intakeOption) {
                        if (intakeOption.active === '1') {
                            vm.activeIntakeOptions.push(intakeOption);
                        }
                    })
                    this.intakeOptions = vm.activeIntakeOptions
                    if (this.hzzEntry) {
                        if (this.hzzData.idIntakeTreatment > 0) {
                            this.selectHzzTreatment(this.intakeOptions)
                        }
                        if (this.hzzData.hzzEstablishment > 0 && this.establishments.length > 1) {
                            this.selectHzzEstablishment(this.establishmentsByTreatment)
                        }
                    } else if (this.intakeOptions.length < 1) {
                        this.noIntakeOptions = true
                    }
                    if (this.intakeOptions.length === 1) {
                        this.selectedTreatment = this.intakeOptions[0]
                    }
                    if (this.idPreselectedTreatment && this.idPreselectedTreatment > 0) {
                        this.intakeOptions.forEach(function (intakeOption) {
                            if (intakeOption.id === vm.idPreselectedTreatment) {
                                vm.selectedTreatment = intakeOption
                            }
                        })
                    }
                    this.$store.commit('setIntakeOptions', this.intakeOptions)
                } else {
                    this.noIntakeOptions = true
                }
            })
        },
        getMinutes(timeString) {
            if (timeString !== 'null' && timeString !== '00:00:00') {
                let minutes = parseInt(timeString.substr(3, 2))
                let hours = parseInt(timeString.substr(0, 2))
                if (hours > 0) {
                    minutes = minutes + (hours * 60)
                }
                if (minutes > 0) {
                    return minutes
                } else {
                    return null
                }
            } else {
                return null
            }
        },
        checkPostalCode() {
            if (this.$store.state.patientPostalCode != null) {
                this.getEstablishmentsByPostalCode(this.$store.state.patientPostalCode);
            } else {
                this.disableInput = true
                this.showPostalCode = true
            }
        },
        getEstablishmentsByPostalCode(postalCode) {
            axios.get(process.env.VUE_APP_API_URL + 'byPostalCode/?practice=' + this.practiceCode + this.tokenString,
                {
                    params: {
                        'postalCode': postalCode,
                        'logToken': this.logToken
                    }
                }).then((response) => {

                let establishments = response.data.establishments;
                let activeEstablishments = [];
                if (establishments.length === 1) {
                    this.oneOption = true
                } else {
                    establishments.forEach(function (establishment) {
                        if (establishment.active === '1' && establishment.visibleInAs === '1') {
                            activeEstablishments.push(establishment);
                        }
                    })
                    this.activeEstablishments.push(activeEstablishments)
                    this.establishments = activeEstablishments
                    this.disableInput = false
                }
            })
        },
        getEstablishmentsByTreatment(id) {
            axios.get(process.env.VUE_APP_API_URL + 'getEstablishmentsByTreatment/?practice=' + this.practiceCode + this.tokenString,
                {
                    params: {
                        'treatmentId': id,
                        'logToken': this.logToken
                    }
                }).then((response) => {
                let vm = this
                this.establishmentsByTreatment = []
                if (response.data.getEstablishmentByTreatment === 'success') {
                    let tempEstablishments = response.data.establishmentIdByTreatment
                    tempEstablishments.forEach(function (establishmentId) {
                        let establishment = vm.getEstablishmentById(establishmentId)
                        if (vm.rescheduleData.idEstablishment === establishment.id) {
                            vm.selectedEstablishment = establishment
                        }
                        vm.establishmentsByTreatment.push(establishment);
                    })
                    this.establishmentsByTreatment = vm.establishmentsByTreatment
                    if (this.hzzData.hzzEstablishment > 0) {
                        this.selectHzzEstablishment(this.establishmentsByTreatment)
                    } else if (this.establishmentsByTreatment.length === 1) {
                        this.selectedEstablishment = this.establishmentsByTreatment[0]
                    }
                    this.noEstablishment = false
                } else {
                    this.noEstablishment = true
                }
            })
        },
        getEstablishmentById(establishmentId) {
            return this.establishments.filter(function (establishment) {
                return establishment.id === establishmentId
            })[0]
        },
        selectHzzTreatment(intakeOptions) {
            let vm = this
            let checkTreatment = intakeOptions.filter(function (intakeOption) {
                if (intakeOption.id === vm.hzzData.idIntakeTreatment) {
                    return intakeOption
                }
            })[0]
            if (checkTreatment) {
                this.setTreatment(checkTreatment)
            } else {
                this.selectedTreatment = null
            }
        },
        selectHzzEstablishment(establishments) {
            let vm = this
            let checkEstablishment = establishments.filter(function (establishment) {
                if (parseInt(establishment.id) === parseInt(vm.hzzData.hzzEstablishment)) {
                    return establishment
                }
            })[0]
            if (checkEstablishment) {
                this.selectedEstablishment = checkEstablishment
            } else {
                this.selectedEstablishment = null
            }
        }
    },
    watch: {
        establishments: function () {
            if (this.establishments.length === 1) {
                this.selectedEstablishment = this.establishments[0]
            }
            if (!this.intake) {
                this.getEpisodes()
            }
        },
        selectedEstablishment: function () {
            this.$store.commit('setSelectedEstablishment', this.selectedEstablishment)
        },
        selectedEpisode: function () {
            this.$store.commit('setSelectedEpisode', this.selectedEpisode)
            this.getTreatmentData(this.selectedEpisode.treatment)
            this.getEstablishmentsByTreatment(this.selectedEpisode.treatment)
        },
        selectedTreatment: function () {
            this.$store.commit('setSelectedTreatment', this.selectedTreatment)
            let treatment = this.selectedTreatment
            this.getEstablishmentsByTreatment(treatment.idIntakeTreatment)
            this.getTreatmentData(treatment.idIntakeTreatment)
            this.selected = true
        }
    },
    computed: {
        idPatient() {
            return this.$store.state.idPatient
        },
        token() {
            return this.$store.state.token
        },
        tempToken() {
            return this.$store.state.tempToken
        },
        practiceCode() {
            return this.$store.state.practiceCode
        },
        postalCodeCheck() {
            return this.$store.state.postalCodeCheck
        },
        showDescriptionPrice() {
            return this.$store.state.showDescriptionPrice
        },
        practiceHash() {
            return this.$store.getters.getPracticeHash
        },
        hzzData() {
            return this.$store.getters.getHzzData
        },
        rescheduleData() {
            return this.$store.getters.getRescheduleData
        },
        reschedule() {
            return this.$store.getters.getReschedule
        },
        showEstablishmentAddress() {
            return this.$store.getters.getShowEstablishmentAddress
        },
        logToken() {
            return this.$store.getters.getLogToken
        }
    },
    name: "EstablishmentChoice",
}
</script>

<style>

</style>