<template>
    <b-row class="justify-content-md-center">
        <b-spinner label="Spinner"></b-spinner>
        <div class="w-100"></div>
        <p>{{ text }}...</p>
    </b-row>
</template>

<script>
export default {
    name: 'Loader',
    props: {
        text: String
    }
}
</script>

<style scoped>

</style>