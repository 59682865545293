<template>
    <div class="col-12 col-sm-9">
        <div class="header-bar" id="header-bar">
            <h4>Plan je afspraak</h4>
        </div>
        <div
            class="row"
            v-if="next === 'fullPage'"
        >
            <div class="col-sm-6 mb-2">
                <div class="card">
                    <div class="card-body">
                        <h5>Inloggen</h5>
                        <login-component></login-component>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="card">
                    <div class="card-body">
                        <div v-if="intakeEnabled">
                            <h5>Ben je nieuw bij ons?</h5>
                            <button
                                type="button"
                                class="btn btn-primary"
                                v-on:click="this.intake"
                            >
                                Plan een afspraak
                            </button>
                        </div>
                        <div v-else>
                            <h5 v-if="typeof intakeText.header !== 'undefined'">
                                {{ intakeText.header }}</h5>
                            <p v-if="typeof intakeText.body !== 'undefined'">
                                {{ intakeText.body }}</p>
                            <p v-if="typeof intakeText.phoneNumber !== 'undefined'">
                                <a :href="`tel:${intakeText.phoneNumber}`">
                                    <button
                                        type="button"
                                        class="btn btn-success"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            class="bi bi-telephone"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"></path>
                                        </svg>
                                        {{ intakeText.phoneNumber }}
                                    </button>
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="next === 'login'">
            <div class="col-sm-12 mb-4">
                <div class="card">
                    <div class="card-body">
                        <h5>Inloggen</h5>
                        <login-component></login-component>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LoginComponent from "./Login";

export default {
    name: "Welcome",
    components: {LoginComponent},
    created() {
        if (this.next === 'intake') {
            this.intake()
        }
    },
    data() {
        return {
            treatments: []
        }
    },
    props: {
        next: String
    },
    methods: {
        intake() {
            this.$store.commit('setIntake', true)
            this.$router.push({
                name: 'establishment',
                params: {practiceHash: this.$store.state.practiceHash}
            })
        },
    },
    computed: {
        practiceCode() {
            return this.$store.state.practiceCode
        },
        intakeEnabled() {
            return this.$store.getters.getIntakeEnabled;
        },
        intakeText() {
            return this.$store.getters.getIntakeText;
        }
    }
}
</script>

<style scoped>

</style>