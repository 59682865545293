<template>
    <div
        class="col-12 col-sm-3"
        v-if="(this.headerInfo.showLogo || this.headerInfo.showPracticeData || this.hzzEntry)"
    >
        <div
            class="card"
            v-if="headerReady"
            style="margin-top: 10%;"
        >
            <div
                class="d-flex justify-content-center"
                v-if="this.headerInfo.showLogo"
            >
                <img
                    v-bind:src="logoUrl"
                    alt=""
                    class="card-img-top"
                >
            </div>
            <div
                class="card-body"
                v-if="this.headerInfo.showPracticeData"
            >
                <h5 class="card-title" id="practice-name">{{ headerInfo.practiceInfo.practiceName }}</h5>
                <p class="card-text" id="practice-address">
                    {{ headerInfo.practiceInfo.practiceAddress }} <br> {{ headerInfo.practiceInfo.practicePostalCode }},
                    {{ headerInfo.practiceInfo.practiceCity }}
                </p>
                <p class="card-text textbox">
                    <small class=" "> {{ headerInfo.practiceInfo.practicePhoneNumber }} <br>
                                      {{ headerInfo.practiceInfo.practiceMail }} </small>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Header",
    data() {
        return {
            headerReady: false
        }
    },
    methods: {},
    computed: {
        headerInfo() {
            return this.$store.state.headerInfo
        },
        logoUrl() {
            return this.$store.state.logo
        },
        hzzEntry() {
            return this.$store.state.hzzEntry
        }
    },
    watch: {
        headerInfo() {
            this.headerReady = true
        }
    }
}
</script>

<style scoped>
.card {
    border: 0;
    background-color: rgba(255, 255, 255, 0.4);
}

img {
    width: 100%;
    max-width: 300px;
}

</style>