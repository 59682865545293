<template>
    <div class="col-12 col-sm-9">
        <div class="header-bar">
            <h4>Wachtwoord aanmaken</h4>
        </div>
        <div class="card-body">
            <password-component @password-valid="passwordValid"></password-component>
            <div class="d-flex justify-content-center">
                <button
                    @click="createPassword"
                    class="btn btn-primary"
                >
                    Maak wachtwoord aan
                </button>
            </div>
        </div>
        <modal v-show="isModalVisible">
            <h3
                slot="header"
                class="modal-title"
            >
                Wachtwoord aangemaakt
            </h3>
            <div
                slot="body"
                style="padding: 20px"
            >
                <p>Het wachtwoord is aangepast, klik op ok om terug te gaan naar het hoofdscherm</p>
            </div>
            <div slot="footer">
                <button
                    type="button"
                    class="btn btn-primary"
                    v-on:click="back"
                >Ok
                </button>
            </div>
        </modal>
    </div>

</template>

<script>
import axios from 'axios'
import Modal from "./Modal";
import PasswordComponent from "./PasswordComponent";

export default {
    name: "Password",
    data() {
        return {
            password: '',
            passwordCheck: '',
            passwordVisible: false,
            showButton: false,
            isModalVisible: false,
            practiceCode: null,
            isPasswordValid: false,
            valid_password: false,
        }
    },
    methods: {
        createPassword() {
            if (this.valid_password && this.emailToken) {
                axios.put(process.env.VUE_APP_API_URL + 'setpassword?practice=' + this.practiceCode,
                    {emailToken: this.emailToken, password: this.password}
                ).then(() => {
                    this.isModalVisible = true;
                }).catch(error => console.log(error))
            }
        },
        back() {
            this.$router.push({
                name: 'home',
                params: {practiceHash: this.$route.params.practiceHash}
            });

        },
        getClientId(hash) {
            axios.post(process.env.VUE_APP_API_URL + 'practiceCode',
                {
                    hash: hash,
                    logToken: this.$store.getters.getLogToken || null
                }
            ).then((response) => {
                this.practiceCode = response.data.practiceCode.practiceCode
            })
        },
        passwordValid(valid, password) {
            this.valid_password = valid
            this.password = password
        }
    },
    created() {
        this.getClientId(this.$route.params.practiceHash);
        this.emailToken = this.$route.params.emailToken
    },
    components: {
        PasswordComponent,
        Modal
    }
}
</script>

<style scoped>


</style>