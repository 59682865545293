<template>
    <button
        type="button"
        class="btn btn-dark btn-sm btn-logoff"
        @click="logOff"
        id="btn-logoff"
    >
        <b-icon
            icon="power"
            aria-hidden="true"
        ></b-icon>
        Uitloggen
    </button>
</template>

<script>
import logOff from "../../mixins/calls";

export default {
    name: "LogOffButton",
    mixins: [logOff],
    methods: {
    }
}
</script>
