<template>
    <div class="container-lg">
        <div
            v-if="wait"
            class="d-flex justify-content-center align-items-center"
            style="min-height: 80vh"
        >
            <Loader text="Laden"></Loader>
        </div>
        <div
            class="row d-flex justify-content-center"
            v-else
        >
            <component :is="`style`">
                :root {
                <!--        general colors-->
                --background: {{ colors.generalBackground }};
                --background-body: {{ colors.generalBackgroundBody }};
                --text: {{ colors.generalText }};
                <!--        headerColors-->
                --header-background: {{ colors.headerBackground }};
                --header-text: {{ colors.headerText }};
                <!--        input colors-->
                --input-background: {{ colors.inputBackground }};
                --input-text: {{ colors.inputText }};
                --input-placeholder: {{ colors.inputPlaceholder }};
                <!--        errorColors-->
                --error-border: {{ colors.errorBorder }};
                --error-text: {{ colors.errorText }};
                <!--        Modal colors-->
                <!--        &#45;&#45;modal-background: {{ colorScheme.modalBackground }};-->
                <!--        &#45;&#45;modal-text: {{ colorScheme.modalText }};-->
                <!--        &#45;&#45;modal-header: {{ colorScheme.modalHeader }};-->
                <!--        &#45;&#45;modal-header-text: {{ colorScheme.modalHeaderText }};-->
                <!--        descriptionColors-->
                --description-background: {{ colors.descriptionBackground }};
                --description-text: {{ colors.descriptionText }};
                <!--        primary button colors-->
                --primary-button: {{ colors.primaryButtonBackground }};
                --primary-button-text: {{ colors.primaryButtonText }};
                <!--        log off btn colors-->
                --logoff-button: {{ colors.logOffButtonBackground }};
                --logoff-button-text: {{ colors.logOffButtonText }};
                <!--        backbutton colors-->
                --back-button-border: {{ colors.backButtonBorder }};
                --back-button-text: {{ colors.backButtonText }};
                --back-button-hover: {{ colors.backButtonHover }};
                --back-button-hover-text: {{ colors.backButtonHoverText }};

                <!--        reschedule button colors-->
                --reschedule-button-border: {{ colors.rescheduleButtonBorder }};
                --reschedule-button-text: {{ colors.rescheduleButtonText }};
                --reschedule-button-hover: {{ colors.rescheduleButtonHover }};
                --reschedule-button-hover-text: {{ colors.rescheduleButtonHoverText }};

                <!--        cancel button colors-->
                --cancel-button-border: {{ colors.cancelButtonBorder }};
                --cancel-button-text: {{ colors.cancelButtonText }};
                --cancel-button-hover: {{ colors.cancelButtonHover }};
                --cancel-button-hover-text: {{ colors.cancelButtonHoverText }};
                }
            </component>
            <Header></Header>
            <router-view></router-view>
        </div>
    </div>
</template>

<script>

import Header from "./components/Header";
import axios from "axios";
import getInfo from "./mixins/getInfo";
import Loader from "./components/Loader/Loader";
import Logging from "@/mixins/logging";

export default {
    name: 'app',
    components: {Header, Loader},
    mixins: [getInfo, Logging],
    data() {
        return {
            practiceId: this.$store.state.practiceCode,
            practiceCode: null,
            wait: true,
        }
    },
    created() {
        sessionStorage.clear();
        this.setLogToken()
        this.getClientId(this.$route.params.practiceHash);
        this.$store.commit('setPracticeHash', this.$route.params.practiceHash);
        this.$store.commit('setPageHistory', false)
        this.$store.commit('setLogToken', false)

    },
    methods: {
        getClientId(hash) {
            axios.post(process.env.VUE_APP_API_URL + 'practiceCode',
                {
                    hash: hash,
                    logToken: this.$store.getters.getLogToken
                }
            ).then((response) => {
                this.practiceCode = response.data.practiceCode.practiceCode
                this.$store.commit('setPracticeCode', this.practiceCode)
                this.setUpInfo()
                this.wait = false
            })
        },
    },
    computed: {
        colors() {
            return this.$store.getters.getColors
        }
    },
    watch: {
        $route(to) {
            this.$store.commit('setPageHistory', to.name)
            if (
                to.name !== 'confirmation'
                ||
                to.name !== 'home'
            ) {
                this.updatePageLog()
            }
        }
    }
}
</script>

<style>

body {
    /*background: #e3e9f7 url("https://www.skinadminlogin.nl/images/login/background.jpg") no-repeat center center fixed;*/
    /*background-size: cover;*/
    /*background-color: rgb(240, 240, 244) !important;*/
    background-color: var(--background) !important;
    font-size: 0.875rem !important;
    /*color: #333 !important;*/
}

.container-lg {
    /*background-color: #fdfdfd;*/
    background-color: var(--background-body) !important;
    color: var(--text) !important;
    border-radius: 5px;
    min-height: 88vh;
}

.header-bar {
    /*background-color: #6a9ad9 !important;*/
    background-color: var(--header-background) !important;
    color: var(--header-text) !important;
    padding: 0.75rem 1.25rem;
    margin-bottom: 0.75rem;
}

.card-body {
    /*background-color: #fdfdfd;*/
    background-color: var(--background-body) !important;
    color: var(--text) !important;
}

.b-table {
    color: var(--text) !important;
}

.textbox {
    color: var(--text) !important;
}

.description {
    margin-top: 1rem;
    /*background-color: #f2f2f2 !important;*/
    color: var(--description-text) !important;
    background: var(--description-background) !important;
    border-color: #dce4f2 !important;
}

.btn-primary {
    /*background-color: #6a9ad9 !important;*/
    background-color: var(--primary-button) !important;
    color: var(--primary-button-text) !important;
    border: none !important;
}

/*.btn-outline-secondary {*/
/*  !*background-color: #6a9ad9 !important;*!*/
/*  border-color:var(--back-button) !important;*/
/*  color:var(--back-button-text) !important;*/
/*  border: none !important;*/
/*}*/
.btn-logoff {
    /*background-color: #5c6273 !important;*/
    background-color: var(--logoff-button) !important;
    color: var(--logoff-button-text) !important;

    border: none !important;
}

.btn-back {
    border-color: var(--back-button-border) !important;
    color: var(--back-button-text) !important;
}

.btn-back:hover {
    background-color: var(--back-button-hover) !important;
    color: var(--back-button-hover-text) !important;
    transition: 0.7s;
}

.btn-cancel {
    border-color: var(--cancel-button-border) !important;
    color: var(--cancel-button-text) !important;
}

.btn-cancel:hover {
    background-color: var(--cancel-button-hover) !important;
    color: var(--cancel-button-hover-text) !important;
    transition: 0.7s;
}

.btn-reschedule {
    border-color: var(--reschedule-button-border) !important;
    color: var(--reschedule-button-text) !important;
}

.btn-reschedule:hover {
    background-color: var(--reschedule-button-hover) !important;
    color: var(--reschedule-button-hover-text) !important;
    transition: 0.7s;
}

.form-control {
    color: var(--input-text) !important;
    background-color: var(--input-background) !important;
}

.form-control-lg {
  font-size: 1rem !important;
}

.mx-input{
  color: var(--input-text) !important;
  background-color: var(--input-background) !important;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--input-placeholder) !important;
    opacity: 1; /* Firefox */
}

.error {
    color: var(--error-text) !important;
}

.disabled {
    background-color: lightgray !important;
}
.form-group--error {
    border-color: var(--error-border) !important;
}

ul {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

li {
    margin-bottom: 8px;
    color: black;
    position: relative;
}

li:before {
    content: "";
    width: 0%;
    height: 2px;
    /*background: #2ecc71;*/
    position: absolute;
    left: 0;
    top: 50%;
    display: block;
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #000 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


/* DESKTOP */
@media only screen and (min-width: 768px) {
    .container-lg {
        margin-top: 50px;
    }
}
</style>
