<template>
    <b-container>
        <b-row>
            <b-col>
                <b-row>
                    <b-form-group>
                        <b-col>
                            <b-form-input
                                class="form-control"
                                v-on:input="checkPassword"
                                id="password"
                                :type="passwordVisible ? 'text' : 'password'"
                                v-model="password"
                                placeholder="Voer uw wachtwoord in"
                            >
                            </b-form-input>
                        </b-col>
                    </b-form-group>
                    <b-form-group
                        id="show-password-1"
                        label="Toon"
                        label-for="show-password"
                    >
                        <button
                            class="visibility btn"
                            tabindex="-1"
                            id="show-password"
                            @click="togglePasswordVisibility"
                            :arial-label='passwordVisible ? "Hide password" : "Show password"'
                        >
                            <b-icon-eye
                                v-if="!passwordVisible"
                                scale="2"
                            ></b-icon-eye>
                            <b-icon-eye-slash
                                v-if="passwordVisible"
                                scale="2"
                            ></b-icon-eye-slash>
                        </button>
                    </b-form-group>
                </b-row>
                <b-row>
                    <b-form-group>
                        <b-col>
                            <b-form-input
                                :type="passwordVisible ? 'text' : 'password'"
                                class="form-control"
                                id="password-check"
                                v-model="passwordCheck"
                                v-on:input="checkPassword"
                                @keyup.enter="checkPassword"
                                placeholder="Herhaal uw wachtwoord"
                            >

                            </b-form-input>
                        </b-col>
                    </b-form-group>
                </b-row>
            </b-col>
            <b-col>
                <div class="d-flex justify-content-center ">
                    <div>
                        <h4 class="text-center">Wachtwoord bevat:</h4>
                        <ul>
                            <li>
                                <div v-if="contains_eight_characters">
                                    <s> Minimaal 8 karakters </s>
                                </div>
                                <div v-else>
                                    Minimaal 8 karakters
                                </div>
                            </li>
                            <li>
                                <div v-if="contains_number">
                                    <s> Een cijfer </s>
                                </div>
                                <div v-else>
                                    Een cijfer
                                </div>
                            </li>
                            <li>
                                <div v-if="contains_uppercase">
                                    <s> Een hoofdletter </s>
                                </div>
                                <div v-else>
                                    Een hoofdletter
                                </div>
                            </li>
                            <li>
                                <div v-if="contains_special_character">
                                    <s> Een speciaal karakter </s>
                                </div>
                                <div v-else>
                                    Een speciaal karakter
                                </div>
                            </li>
                            <li>
                                <div v-if="matchingPasswords">
                                    <s> Wachtwoorden komen overeen </s>
                                </div>
                                <div v-else>
                                    Wachtwoorden komen overeen
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import {BIconEye, BIconEyeSlash} from 'bootstrap-vue';
import axios from "axios";

export default {
    name: "PasswordComponent",
    data() {
        return {
            password: '',
            passwordCheck: '',
            passwordVisible: false,
            contains_eight_characters: false,
            contains_number: false,
            contains_uppercase: false,
            contains_special_character: false,
            matchingPasswords: false,
            showButton: false,
            valid_password: false,
            isModalVisible: false,
            practiceCode: null
        }
    },
    methods: {
        checkPassword() {
            this.password_length = this.password.length;
            //eslint-disable-next-line
            const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

            this.contains_eight_characters = this.password_length >= 8;

            this.contains_number = /\d/.test(this.password);
            this.contains_uppercase = /[A-Z]/.test(this.password);
            this.contains_special_character = format.test(this.password);
            this.matchingPasswords = (this.password === this.passwordCheck) && this.password !== '';

            if (this.contains_eight_characters === true &&
                this.contains_special_character === true &&
                this.contains_uppercase === true &&
                this.contains_number === true &&
                this.matchingPasswords === true) {
                this.valid_password = true;
            } else {
                this.valid_password = false;
            }
            this.passwordValid(this.valid_password)
        },
        passwordValid(valid) {
            this.$emit("password-valid", valid, this.password)
        },
        togglePasswordVisibility() {
            this.passwordVisible = !this.passwordVisible
        },
        back() {
            this.$router.push({
                name: 'home',
                params: {practiceHash: this.$route.params.practiceHash}
            });

        },
        getClientId(hash) {
            axios.post(process.env.VUE_APP_API_URL + 'practiceCode',
                {
                    hash: hash,
                    logToken: this.$store.getters.getLogToken || null
                }
            ).then((response) => {
                this.practiceCode = response.data.practiceCode.practiceCode
            })
        }
    },
    created() {
        this.getClientId(this.$route.params.practiceHash);
        this.emailToken = this.$route.params.emailToken
    },
    watch: {
        password(value) {
            this.$emit('update-password', value, this.valid_password)
        }
    },
    components: {
        BIconEye, BIconEyeSlash
    }
}
</script>

<style scoped>

</style>