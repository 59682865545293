import axios from "axios";

export default {
    methods: {
        setLogToken(resetToken = false) {
            if (!this.$store.getters.getLogToken || resetToken) {
                let len = 20
                let text = ""
                let chars = "abcdefghijklmnopqrstuvwxyz1234567890!@#$%^&*()"

                for (let i = 0; i < len; i++) {
                    text += chars.charAt(Math.floor(Math.random() * chars.length))
                }
                let logToken = String(text) + Math.random().toString(36).slice(2);
                this.$store.commit('setLogToken', logToken)
            }
        },
        updatePageLog() {
            if (!this.$store.getters.getLogToken) {
                this.setLogToken()
            }
            return axios.post(process.env.VUE_APP_API_URL + 'updateLog?practice=' + this.practiceCode,
                {
                    establishmentPostalCode: (this.$store.getters.getSelectedEstablishment) ? this.$store.getters.getSelectedEstablishment.postalCode : null,
                    treatmentName: (this.$store.getters.getTreatmentData) ? this.$store.getters.getTreatmentData.name : null,
                    logToken: this.$store.getters.getLogToken,
                    pageHistory: this.$store.getters.getPageHistory,
                    intake: this.$store.getters.getIntake,
                    hzzData: this.$store.getters.getHzzData
                }
            )
        },
        updateConversionLog(idAppointment) {
            if (!this.$store.getters.getLogToken) {
                this.setLogToken()
            }
            return axios.post(process.env.VUE_APP_API_URL + 'updateLogWithConversion?practice=' + this.practiceCode,
                {
                    logToken: this.$store.getters.getLogToken,
                    pageHistory: this.$store.getters.getPageHistory,
                    intake: this.$store.getters.getIntake,
                    conversion: true,
                    idAppointment: idAppointment,
                    treatmentName: (this.$store.getters.getTreatmentData) ? this.$store.getters.getTreatmentData.name : null,
                    hzzData: this.$store.getters.getHzzData
                }
            )
        },
    }
}