<template>
    <div class="col-12 col-sm-9">
        <div class="header-bar">
            <h4>Welkom</h4>
        </div>
        <div class="row">
            <div class="col-sm-6 mb-2">
                <div class="card">
                    <div class="card-body">
                        <p> Er is iets mis gegaan, controleer de url</p>
                        <div v-if="this.$store.state.practiceHash">
                            <button
                                class="btn btn-back"
                                v-on:click="reroute"
                            >klik hier om naar de startpagina te gaan
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Landing",
    methods: {
        reroute() {
            this.$router.push({
                name: 'home',
                params: {practiceHash: this.$store.state.practiceHash}
            })
        }
    }
}
</script>

<style scoped>

</style>