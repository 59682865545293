import axios from "axios";
import calls from "@/mixins/calls";

export default {
    mixins: [calls],
    methods: {
        getAppointmentOptions: function () {
            this.wait = true;
            this.$store.commit('setNumberOfOptions', 0)
            this.$store.commit('setAppointmentOptionsReady', false)
            let url = process.env.VUE_APP_API_URL + 'planagenda/by/day?practice=' + this.practiceCode + this.tokenString
            axios.post(url, {
                dataArray: this.dataArray,
                idPatient: this.idPatient,
                logToken: this.$store.getters.getLogToken || null
            }).then((response) => {
                if (response.data.appointmentOptionsArray) {
                    this.$store.commit('setNumberOfOptions', response.data.numberOfOptions)
                    this.$store.commit('setAppointmentOptions', response.data.appointmentOptionsArray)
                    this.$store.commit('setAppointmentOptionsReady', true)
                    this.appointmentOptionsReadyPush()
                } else {
                    this.$store.commit('setAppointmentOptions', null)
                    this.$store.commit('setAppointmentOptionsReady', true)
                    this.appointmentOptionsReadyPush()
                }
            })
        },
        appointmentOptionsReadyPush: function () {
            this.wait = false
            if (this.$route.name === 'establishment') {
                this.$router.push({
                    name: 'appointment',
                    params: {practiceHash: this.$store.state.practiceHash}
                })
            }
        },
    },
    created() {
        if (this.$store.getters.getToken) {
            this.tokenString = '&api-token=' + this.$store.state.token
        } else {
            this.tokenString = ''
        }
    },
}
