<template>
    <div class="col-12 col-sm-9">
        <div>
            <div class="header-bar">
                <h4>Gegevens aanvullen</h4>
            </div>
            <div class="card-body">
                <b-col>
                    <div class="form-group">
                        <b-row>
                            <b-col sm="4">
                                <b-form-input
                                    :class="{ 'form-group--error': $v.firstName.$error }"
                                    @input="setFirstName($event)"
                                    class="form__input"
                                    id="first-name"
                                    placeholder="Voornaam"
                                    type="text"
                                    v-model.trim="$v.firstName.$model"
                                ></b-form-input>
                                <span
                                    class="error"
                                    v-if="!$v.firstName.required && error"
                                >Voer voornaam in</span>
                            </b-col>
                            <b-col sm="4">
                                <b-form-input
                                    type="text"
                                    class="form__input"
                                    v-model.trim="infix"
                                    id="suffix"
                                    :value="infix"
                                    placeholder="tussenvoegsel"
                                ></b-form-input>
                            </b-col>
                            <b-col sm="4">
                                <b-form-input
                                    :class="{ 'form-group--error': $v.lastName.$error }"
                                    type="text"
                                    class="form__input"
                                    v-model.trim="$v.lastName.$model"
                                    id="last-name"
                                    @input="setLastName($event)"
                                    placeholder="Achternaam"
                                ></b-form-input>
                                <span
                                    class="error"
                                    v-if="!$v.lastName.required && error"
                                >Voer achternaam in</span>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="form-group">
                        <b-row>
                            <b-col sm="3">
                                <vue2-datepicker
                                    :class="{ 'form-group--error': $v.dateOfBirth.$error }"
                                    class="form__input"
                                    id="birthdate"
                                    v-model="$v.dateOfBirth.$model"
                                    :format="'DD-MM-YYYY'"
                                    placeholder="Geboortedatum"
                                    style="width: 100%"
                                    @input="setBirthDate($event)"
                                >
                                </vue2-datepicker>
                                <span
                                    class="error"
                                    v-if="!$v.dateOfBirth.required && error"
                                >Voer geboortedatum in
                                    </span>
                            </b-col>
                            <b-col sm="3">
                                <b-form-select
                                    v-model="gender"
                                    class="form-control form__input"
                                    :class="{ 'form-group--error': $v.gender.$error }"
                                >
                                    <option
                                        :value="null"
                                        disabled
                                    >Geslacht
                                    </option>
                                    <option value="male">Man</option>
                                    <option value="female">Vrouw</option>
                                </b-form-select>
                                <span
                                    class="error"
                                    v-if="!$v.gender.required && error"
                                >Voer geslacht in
                                    </span>
                            </b-col>
                            <b-col sm="6">
                                <b-form-input
                                    type="tel"
                                    :class="{ 'form-group--error': $v.phoneNumber_1.$error }"
                                    class="form__input"
                                    v-model.trim="$v.phoneNumber_1.$model"
                                    id="phonenumber"
                                    @input="setPhoneNumber($event)"
                                    placeholder="Telefoonnummer (mobiel)"
                                ></b-form-input>
                                <span
                                    class="error"
                                    v-if="!$v.phoneNumber_1.required && error"
                                >Voer telefoonnummer
                                        in
                                    </span>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="form-group">
                        <b-row>
                            <b-col lg="12">
                                <b-form-input
                                    type="email"
                                    class="form__input"
                                    :class="{ 'form-group--error': $v.email.$error }"
                                    v-model.trim="$v.email.$model"
                                    id="email"
                                    @input="setEmail($event)"
                                    placeholder="Email"
                                ></b-form-input>
                                <span
                                    class="error"
                                    v-if="!$v.email.required && error"
                                >Voer email in</span>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="form-group">
                        <b-row>
                            <b-col sm="4">
                                <b-form-input
                                    type="text"
                                    class="form__input"
                                    :class="{ 'form-group--error': $v.postalCode.$error }"
                                    v-model.trim="$v.postalCode.$model"
                                    id="postal-code"
                                    @input="setPostalCode($event)"
                                    placeholder="Postcode"
                                ></b-form-input>
                                <span
                                    class="error"
                                    v-if="!$v.postalCode.required && error"
                                >Voer postcode in</span>
                            </b-col>
                            <b-col sm="4">
                                <b-form-input
                                    type="text"
                                    :class="{ 'form-group--error': $v.addressNumber.$error }"
                                    class="form__input"
                                    v-model.lazy="$v.addressNumber.$model"
                                    id="addressNumber"
                                    @input="setAddressNumber($event)"
                                    placeholder="Huisnummer"
                                ></b-form-input>
                                <span
                                    class="error"
                                    v-if="!$v.addressNumber.required && error"
                                >Voer huisnummer in
                                    </span>
                            </b-col>
                            <b-col sm="4">
                                <b-form-input
                                    type="text"
                                    class="form__input"
                                    v-model.trim="addressNumberAddition"
                                    id="addressNumberAddition"
                                    :value="addressNumberAddition"
                                    placeholder="Toevoeging"
                                ></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="12">
                                    <span
                                        class="error"
                                        v-if="invalidAddress"
                                    >Controleer postcode + huisnummer</span>
                            </b-col>
                        </b-row>
                    </div>
                    <div>
                        <b-row>
                            <b-col sm="6">
                                <b-form-input
                                    class="disabled"
                                    type="text"
                                    id="straat"
                                    v-model="$v.street.$model"
                                    placeholder="Straat"
                                    disabled
                                ></b-form-input>

                            </b-col>
                            <b-col sm="6">
                                <b-form-input
                                    class="disabled"
                                    type="text"
                                    v-model="$v.city.$model"
                                    id="city"
                                    placeholder="Stad"
                                    disabled
                                ></b-form-input>
                            </b-col>
                        </b-row>
                    </div>
                </b-col>
                <div>
                    <hr>
                    <br>
                    <div class="form-group">
                        <b-row>
                            <b-col>
                                <GoBack></GoBack>
                            </b-col>
                            <b-col>
                                <div class="d-flex">
                                    <button
                                        id="btn-signup"
                                        v-on:click="submit"
                                        type="button"
                                        class="btn btn-primary"
                                    >
                                        <i
                                            class="icon-hand-right"
                                        ></i>
                                        Schrijf in en plan afspraak
                                    </button>
                                    <span style="margin-left:8px;"></span>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </div>
            </div>
        </div>
        <modal v-show="showErrorModal">
            <h3
                slot="header"
                class="modal-title"
            >
                Reeds bekend
            </h3>
            <button
                slot="header"
                type="button"
                class="close"
                @click="showErrorModal = false"
            >
                <span aria-hidden="true">&times;</span>
            </button>
            <div
                slot="body"
                style="padding: 20px"
            >
                <div v-if="existingAccount">
                    <p>U bent al bekend in het systeem.</p>
                    <p>Ga naar het loginscherm om in te loggen of om een nieuw wachtwoord aan te vragen.</p>
                </div>
                <div v-if="creationError">
                    <p>Er is iets misgegaan bij het aanmaken van het account.</p>
                </div>
            </div>
            <div
                slot="footer"
                style="width: 100%"
            >
                <b-row>
                    <b-col>
                        <button
                            class="btn btn-primary"
                            v-on:click="back"
                        >Naar loginscherm
                        </button>
                    </b-col>
                    <b-col>
                        <button
                            class="btn btn-back"
                            @click="showErrorModal = false"
                            style="float: right"
                        >Sluiten
                        </button>
                    </b-col>
                </b-row>
            </div>
        </modal>
    </div>
</template>

<script>
import axios from 'axios'
import {email, minLength, required} from 'vuelidate/lib/validators'
import Modal from "./Modal";
import GoBack from "./GoBack";
import appointmentOption from "../mixins/appointmentOption";
import appointmentCalls from "@/mixins/appointmentCalls";
import episodeCalls from "@/mixins/EpisodeCalls";


export default {
    name: "Account",
    components: {Modal, GoBack},
    mixins: [appointmentOption, appointmentCalls, episodeCalls],
    created() {
        if (this.$store.getters.getIdPatient && !this.$store.getters.getTempIdPatient) {
            this.$router.push({
                name: 'date-selection',
                params: {practiceHash: this.$store.state.practiceHash}
            })
        }
    },
    data() {
        return {
            firstName: null,
            lastName: null,
            infix: '',
            dateOfBirth: null,
            email: null,
            phoneNumber_1: null,
            postalCode: null,
            addressNumber: null,
            emailActive: 1,
            addressNumberAddition: '',
            street: null,
            city: null,
            error: false,
            existingAccount: false,
            showErrorModal: false,
            creationError: false,
            idPatient: null,
            locale: 'nl',
            gender: null,
            invalidAddress: false,
        }
    },
    validations: {
        firstName: {
            required,
            minLength: minLength(2)
        },
        lastName: {
            required,
            minLength: minLength(2)
        },
        dateOfBirth: {
            required
        },
        email: {
            required,
            email
        },
        phoneNumber_1: {
            required,
            minLength: minLength(10)
        },
        postalCode: {
            required,
            minLength: minLength(6)
        },
        addressNumber: {
            required,
            minLength: minLength(1)
        },
        street: {
            required
        },
        city: {
            required
        },
        gender: {
            required
        },
    },
    methods: {
        submit() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.error = true
            } else {
                this.register()
            }
        },
        check() {
            if (this.postalCode && this.addressNumber) {
                this.getAddress()
            }
        },
        setFirstName(value) {
            this.firstName = value
            this.$v.firstName.$touch()
        },
        setLastName(value) {
            this.lastName = value
            this.$v.lastName.$touch()
        },
        setBirthDate(value) {
            this.dateOfBirth = value
            this.$v.dateOfBirth.$touch()
        },
        setPhoneNumber(value) {
            this.phoneNumber_1 = value
            this.$v.phoneNumber_1.$touch()
        },
        setEmail(value) {
            this.email = value
            this.$v.email.$touch()
        },
        setPostalCode(value) {
            this.postalCode = value
            this.$v.postalCode.$touch()
            this.check()
        },
        setAddressNumber(value) {
            this.addressNumber = value
            this.$v.addressNumber.$touch()
            this.check()
        },
        getAddress() {
            axios.get(process.env.VUE_APP_API_URL + 'address/?practice=' + this.practiceCode + '&postcode=' + this.postalCode + '&number=' + this.addressNumber, {
                params: {logToken: this.logToken}
            }).then((response) => {
                let addressData = response.data.address;
                if (addressData.street === null || addressData.city === null) {
                    this.street = null
                    this.city = null
                    this.invalidAddress = true;
                } else {
                    this.street = addressData.street
                    this.city = addressData.city
                    this.invalidAddress = false;
                }
            })
        },
        register: function () {
            axios.post(process.env.VUE_APP_API_URL + 'createpatient/?practice=' + this.practiceCode, {
                firstName: this.firstName,
                lastName: this.lastName,
                infix: this.infix,
                dateOfBirth: this.$moment(this.dateOfBirth).format('YYYY-MM-DD'),
                email: this.email,
                gender: this.gender,
                phoneNumber_1: this.phoneNumber_1,
                postalCode: this.postalCode,
                addressNumber: this.addressNumber,
                addressNumberAddition: this.addressNumberAddition,
                emailActive: 1,
                street: this.street,
                city: this.city,
                practice: parseInt(this.$store.state.selectedEstablishment.practice),
                disease: parseInt(this.$store.state.selectedTreatment.idCondition),
                treatment: parseInt(this.$store.state.selectedTreatment.idIntakeTreatment),
                logToken: this.$store.getters.getLogToken || null
            })
                .then((response) => {
                        if (response.data.createdPatient) {
                            this.idPatient = response.data.patientId
                            this.$store.commit('setIdPatient', response.data.patientId)
                            this.$store.commit('setAccountLess', true)
                            this.continue()
                        } else {
                            if (response.data.knownPatient && response.data.knownPatient === true) {
                                if (response.data.accountLess && response.data.accountLess === true) {
                                    this.idPatient = response.data.patientId
                                    this.$store.commit('setIdPatient', response.data.patientId)
                                    this.$store.commit('setAccountLess', true)
                                    this.continue()
                                  } else {
                                      this.existingAccount = true
                                      this.showErrorModal = true
                                  }
                            } else {
                                this.creationError = true
                                this.showErrorModal = true
                            }
                        }
                    }
                )
                .catch(error => console.log(error))
        },
        back() {
            this.$store.commit('setToken', null)
            this.$router.push({
                name: 'home',
                params: {practiceHash: this.$route.params.practiceHash}
            });
        },
        continue() {
              this.checkExistingEpisode()
        },
      checkExistingEpisode() {
        this.checkForExistingOpenEpisode().then(existingEpisode => {
          if (existingEpisode) {
            this.$store.state.selectedAppointment.idEpisode = existingEpisode
            this.scheduleAppointment(existingEpisode)
          } else {
            if (this.accountLess) {
              this.createAccountLessEpisode()
            } else {
              this.createEpisode()
            }
          }
        });
      }
    },
    computed: {
        practiceCode() {
            return this.$store.state.practiceCode
        },
        dataArray() {
            return this.$store.getters.getPostArray
        },
        accountLess() {
            return this.$store.state.accountLess
        },
    }
}
</script>

<style scoped>

.btn {
    align: center;
}
</style>