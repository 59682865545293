import axios from "axios";

export default {
    data() {
        return {
            practiceCode: this.$store.state.practiceCode,
        }
    },
    methods: {
        setUpInfo() {
            this.setLogToken()
            this.getPostalCodeCheckValue()
            this.getHeaderInfo()
            this.getIntakeSettings()
            this.getShowEstablishmentAddress()
            this.getRedirectUrl()
            this.getLocations()
            this.getGoogleTagManagerId()
            this.getShowDescriptionPrice()
            this.getStyle()
            this.getMutationSettings()
            this.getWeeksAhead()
            this.getMinHoursAhead()
            this.getShowPatientRemark()
        },
        getPostalCodeCheckValue() {
            axios.get(process.env.VUE_APP_API_URL + 'postalCodeCheckValue/?practice=' + this.practiceCode, {
                params: {logToken: this.$store.getters.getLogToken}
            }).then((response) => {
                let results = response.data;
                this.postalCodeCheck = results.value
                this.$store.commit('setPatientPostalCode', null)
                this.$store.commit('setPostalCodeCheck', this.postalCodeCheck)
            })
        },
        getShowDescriptionPrice() {
            axios.get(process.env.VUE_APP_API_URL + 'showDescriptionPrice/?practice=' + this.practiceCode, {
                params: {logToken: this.logToken}
            }).then((response) => {
                let results = response.data;
                this.showDescriptionPrice = results.value
                this.$store.commit('setShowDescriptionPrice', this.showDescriptionPrice)
            })
        },
        getShowEstablishmentAddress() {
            axios.get(process.env.VUE_APP_API_URL + 'showEstablishmentAddress/?practice=' + this.practiceCode, {
                params: {logToken: this.logToken}
            }).then((response) => {
                let results = response.data;
                this.showEstablishmentAddress = results.value
                this.$store.commit('setShowEstablishmentAddress', this.showEstablishmentAddress)
            })
        },
        getHeaderInfo() {
            axios.get(process.env.VUE_APP_API_URL + 'headerInfo/?practice=' + this.practiceCode, {
                params: {logToken: this.logToken}
            }).then((response) => {
                let result = response.data;
                this.$store.commit('setHeaderInfo', result)
                if (
                    result.showLogo
                    && result.logoExists
                ) {
                    this.getLogoLink()
                }
            })
        },
        getLogoLink() {
                this.$store.commit('setLogo', process.env.VUE_APP_API_URL + 'logo/?practice=' + this.$store.state.practiceCode)
        },
        getRedirectUrl() {
            axios.get(process.env.VUE_APP_API_URL + 'redirectUrl/?practice=' + this.practiceCode, {
                params: {logToken: this.logToken}
            }).then((response) => {
                let result = response.data.redirectUrl;
                if (result !== false) {
                    this.$store.commit('setRedirectUrl', result)
                }
            })
        },
        getIntakeSettings() {
            axios.get(process.env.VUE_APP_API_URL + 'getIntakeSettings/?practice=' + this.practiceCode, {
                params: {logToken: this.logToken}
            }).then((response) => {
                this.$store.commit('setIntakeEnabled', response.data.intakeEnabled)
                this.$store.commit('setIntakeText', response.data.intakeText)
            })
        },
        getMutationSettings() {
            axios.get(process.env.VUE_APP_API_URL + 'getMutationSettings/?practice=' + this.practiceCode, {
                params: {logToken: this.logToken}
            }).then((response) => {
                this.$store.commit('setMutationSettings', response.data.mutationSettings)
            })
        },
        getLocations() {
            axios.get(process.env.VUE_APP_API_URL + 'location/?practice=' + this.practiceCode, {
                params: {logToken: this.logToken}
            }).then((response) => {
                let result = response.data;
                if (result !== false) {
                    this.$store.commit('setLocations', result)
                }
            })
        },
        getGoogleTagManagerId() {
            axios.get(process.env.VUE_APP_API_URL + 'gtmId/?practice=' + this.practiceCode, {
                params: {logToken: this.logToken}
            }).then((response) => {
                let result = response.data;
                if (result.gtmId !== false && typeof result.gtmId !== 'undefined') {
                    this.$store.commit('setGtmId', result)
                }
            })
        },
        getStyle() {
            axios.get(process.env.VUE_APP_API_URL + 'getColors/?practice=' + this.practiceCode, {
                params: {logToken: this.logToken}
            }).then((response) => {
                let result = response.data;
                this.$store.commit('setColors', result.colors)
            })
        },
        getWeeksAhead() {
            axios.get(process.env.VUE_APP_API_URL + 'weeksAhead/?practice=' + this.practiceCode).then((response) => {
                let result = response.data;
                this.$store.commit('setWeeksAhead', result.value)
            })
        },
        getMinHoursAhead() {
            axios.get(process.env.VUE_APP_API_URL + 'getMinHoursAhead/?practice=' + this.practiceCode).then((response) => {
                let result = response.data;
                this.$store.commit('setMinHoursAhead', result.value)
            })
        },
        getShowPatientRemark() {
            axios.get(process.env.VUE_APP_API_URL + 'showPatientRemark/?practice=' + this.practiceCode).then((response) => {
                let result = response.data;
                this.$store.commit('setPatientRemarkEnabled', result.value)
            })
        }
    },
    computed: {
        logToken() {
            return this.$store.getters.getLogToken
        }
    }
}