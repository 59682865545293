import axios from "axios";
import Logging from "@/mixins/logging";
import calls from "@/mixins/calls";

export default {
    mixins: [Logging, calls],
    data() {
        return {
            selectedAppointment: this.$store.getters.getSelectedAppointment
        }
    },
    methods: {
        scheduleAppointment(idEpisode) {
            if (!this.selectedAppointment.idEpisode) {
                this.selectedAppointment.idEpisode = idEpisode
            }
            axios.post(process.env.VUE_APP_API_URL + 'planagenda/save/?practice=' + this.practiceCode + this.tokenString,
                {
                    appointmentOption: this.selectedAppointment,
                    idPatient: this.idPatient,
                    idTempPatient: this.idTempPatient,
                    patientRemark: this.patientRemark,
                    hzz: this.$store.state.hzzEntry,
                    logToken: this.$store.getters.getLogToken || null
                }
            ).then((response) => {
                if (response.data.scheduleResult === "success") {
                    this.updateConversionLog(response.data.idAppointment)
                    this.$store.commit('setPageHistory', false)
                    this.$store.commit('setTreatmentData', null)
                    this.$store.commit('setSelectedTreatment', null)
                    this.$store.commit('setSelectedEpisode', null)
                    this.$store.commit('setSelectedEstablishment', null)
                    this.$store.commit('setAppointmentOptions', null)
                    this.$store.commit('setTempToken', false)
                    this.$store.commit('setTempIdPatient', false)
                    this.setLogToken(true)
                    if (this.$store.state.hzzEntry) {
                        this.$store.commit('setHzzEntry', false)
                        this.$store.commit('setHzzData', [])
                    }
                    let rescheduleData = this.$store.getters.getRescheduleData
                    if (this.$store.getters.getReschedule && Object.keys(rescheduleData).length !== 0) {
                        if (this.cancelAppointment(rescheduleData.appointment.id)) {
                            this.$store.commit('setRescheduleData', [])
                            this.$store.commit('setReschedule', false)
                        }
                    }
                    if (response.data.mailResult.result === 'failed') {
                        this.$store.commit('setConfirmMessage', response.data.mailResult.message)
                    }
                    if (this.isIntake
                        && this.$store.state.redirectUrl !== false) {
                        this.logOff()
                    } else {
                        this.$router.push({
                            name: 'confirmation',
                            params: {practiceHash: this.$store.state.practiceHash}
                        });
                    }
                } else {
                    let i = this.appointmentOptions[this.selectedDate].map(item => item.idAppointmentOption).indexOf(this.selectedAppointment.idAppointmentOption)
                    this.appointmentOptions[this.selectedDate].splice(i, 1)
                    this.optionsByDate = this.appointmentOptions[this.selectedDate]
                    this.scheduleError = true
                }
            })
        },
        cancelAppointment(idAppointment) {
            return axios.post(process.env.VUE_APP_API_URL + 'cancelAppointment?practice=' + this.practiceCode + '&api-token=' + this.$store.state.token,
                {
                    patient: parseInt(this.idPatient),
                    idAppointment: parseInt(idAppointment),
                    logToken: this.$store.getters.getLogToken || null
                }
            ).then((response) => {
                return response.data.cancelAppointment === 'success'
            })
        }
    },
    computed: {
        idTempPatient() {
            return this.$store.getters.getTempIdPatient
        },
        episode() {
            return this.$store.getters.getSelectedEpisode
        },
        patientRemark() {
            return this.$store.getters.getPatientRemark
        },
        isIntake() {
            return this.$store.getters.getIntake
        }
    }
}