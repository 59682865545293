<template>
    <div class="col-12 col-sm-9">

        <div class="header-bar">
            <h4>Kies een behandeling en een vestiging</h4>
        </div>


    </div>
</template>

<script>
import axios from "axios";
import Calls from "../mixins/calls";


export default {
    mixins: [Calls],
    created() {
        this.getClientId()
    },
    data() {
        return {
            ready: false
        }
    },
    methods: {
        setHzzInfo() {
            this.$store.commit('setIntake', true)
            this.$store.commit('setHzzEntry', true)

            if (this.$route.params.establishment > 0) {
                this.hzzEstablishment = this.$route.params.establishment
                this.idHzzTreatment = this.$route.params.treatment
                axios.get(process.env.VUE_APP_API_URL + 'getHzzTreatment/?practice=' + this.practiceCode,
                    {
                        params: {
                            'idHzzTreatment': this.idHzzTreatment,
                            'idEstablishment': this.hzzEstablishment,
                            'logToken': this.logToken
                        }
                    }).then((response) => {
                    if (response.data.getHzzTreatment === 'success') {
                        this.$store.commit('setHzzData',
                            {
                                hzzEstablishment: response.data.hhzEstablishment,
                                hzzTreatment: response.data.hzzTreatment.idHzzTreatment,
                                idIntakeTreatment: response.data.hzzTreatment.idDefaultIntakeSettings
                            })
                        this.ready = true
                    } else if (response.data.hhzEstablishment) {
                        this.$store.commit('setHzzData',
                            {
                                hzzEstablishment: response.data.hhzEstablishment,
                            })
                        this.ready = true
                    } else {
                        this.ready = true
                    }
                })
            } else {
                this.ready = true
            }
        }
    },
    watch: {
        ready: function () {
            this.$router.push({
                name: 'establishment',
                params: {
                    practiceHash: this.$store.state.practiceHash
                }
            })
        }
    },
    name: "Hzz",
}
</script>

<style>
</style>